import React, { useState,useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// components
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";

interface FontCategoriesProps {
  selectedFontCategory?: {
    id: number;
    name: string;
    category_icon: string;
  } | null;
  onCategoryAddedOrUpdated: (category: any) => void;
}

const FontCategories: React.FC<FontCategoriesProps> = ({
  selectedFontCategory,
  onCategoryAddedOrUpdated,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  // Form validation schema
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter a category name"),
    })
  );

  const methods = useForm({
    resolver: schemaResolver,
    defaultValues: {
      name: selectedFontCategory?.name || "",
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (selectedFontCategory) {
      setValue("name", selectedFontCategory.name);
      if (selectedFontCategory.category_icon) {
        setUploadedFiles([
          {
            name: "existing",
          preview: selectedFontCategory.category_icon,  // Assuming 'icon' is the image path
          size: 0,
          type: "",
          lastModified: 0,
          webkitRelativePath: "", // Mock value
          arrayBuffer: async () => new ArrayBuffer(0), // Mock function
          slice: () => new Blob([], { type: "" }), // Mock function
          stream: () => new ReadableStream(), // Mock function
          text: async () => "", // Mock function
          },
        ]);
      }
    } else {
      setValue("name", "");
      setUploadedFiles([]);
    }
  }, [selectedFontCategory, setValue]);

  const onFileUpload = (files: any[]) => {
    setUploadedFiles(files);
  };

  // Handle form submission
  const onSubmit = async (data: any) => {
    const formData: any = {
      name: data.name,
    };
  
    if (uploadedFiles.length > 0 && uploadedFiles[0].name !== "existing") {
      const base64File = await toBase64(uploadedFiles[0]);
      formData.category_icon = base64File;
      formData.file_name = uploadedFiles[0].name;
    }
  
    try {
      const url = selectedFontCategory
        ? `https://backend.inkox.com/api/font-categories/${selectedFontCategory.id}`
        : "https://backend.inkox.com/api/fontCategories";
  
      let headers: HeadersInit;
      let method: string;
  
      if (selectedFontCategory) {
        // If editing an existing category
        headers = {
          "X-HTTP-Method-Override": "PUT",
          "Content-Type": "application/json",
        };
        method = "POST";
      } else {
        // If creating a new category
        headers = {
          "Content-Type": "application/json",
        };
        method = "POST";
      }
  
      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error("Something went wrong with the request");
      }
  
      const result = await response.json();
      onCategoryAddedOrUpdated(result); // Trigger the callback with the new or updated category
    } catch (error) {
      console.error("Error adding/updating category:", error);
    }
  };
  
  

  // Helper function to convert a file to Base64
  const toBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
    return (
      <>
        {/* <PageTitle
          breadCrumbItems={[
            { label: "Categories", path: "/apps/ecommerce/categories-detail" },
            {
              label: "Add Font Categories",
              path: "/apps/ecommerce/categories-detail",
              active: true,
            },
          ]}
          title={"Add Font Categories"}
        /> */}
  
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  {/* <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Add Category
                  </h5> */}
                  <FormInput
                    name="name"
                    label="Category Name"
                    placeholder="Enter Category Name"
                    containerClass={"mb-3"}
                    register={register}
                    key="productname"
                    errors={errors}
                    control={control}
                  />
                   <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Category Icon
                  </h5>
                  <FileUploader onFileUpload={onFileUpload} />

                  <Row>
            <Col>
              <div className="text-center mb-3">
                <button
                  type="button"
                  className="btn w-sm btn-light waves-effect me-1"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn w-sm btn-success waves-effect waves-light me-1"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger waves-effect waves-light me-1"
                >
                  Delete
                </button>
              </div>
            </Col>
          </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              {/* <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Product Images
                  </h5>
                  <FileUploader />
                </Card.Body>
              </Card> */}
  
              {/* <Card>
              <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Add Sub Category
                  </h5>
                  <Form.Group className="mb-3">
                  <Form.Label>Assign Main Category</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={categories}
                    id="product-category"
                  />
                </Form.Group>

                  <FormInput
                    name="reference"
                    label="Sub Category Name"
                    placeholder="Enter Sub Category"
                    containerClass={"mb-3"}
                    register={register}
                    key="reference"
                    errors={errors}
                    control={control}
                  />
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                   Sub Category Icon
                  </h5>
                  <FileUploader />
                  <Row>
            <Col>
              <div className="text-center mb-3">
                <button
                  type="button"
                  className="btn w-sm btn-light waves-effect me-1"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn w-sm btn-success waves-effect waves-light me-1"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger waves-effect waves-light me-1"
                >
                  Delete
                </button>
              </div>
            </Col>
          </Row>
  
                </Card.Body>
              </Card> */}
            </Col>
          </Row>
  
          
        </form>
      </>
    );
  };

export default FontCategories;
