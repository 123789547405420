import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface FormValues {
  name: string;
}

interface InkColorDetail {
  id: number;
  color_hex: string;
  color_name: string;
  created_at: string;
  category: {
    name: string;
    image_path: string;
  };
  icon: string;
  category_id: string;
  status: string;
}

interface ColorEntry {
  name: string;
  hex: string;
}

const EditInkColors: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const inkColorDetail = location.state?.detail as InkColorDetail;

  const [colorName, setColorName] = useState<string>("");
  const [colorHex, setColorHex] = useState<string>("");
  const [colors, setColors] = useState<ColorEntry[]>([]);

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (inkColorDetail) {
      const colorNames = inkColorDetail.color_name.split(', ');
      const colorHexCodes = inkColorDetail.color_hex.split(', ');
      const loadedColors: ColorEntry[] = colorNames.map((name, index) => ({
        name: name.trim(),
        hex: colorHexCodes[index]?.trim() || '',
      }));
      setColors(loadedColors);
    }
  }, [inkColorDetail]);

  const handleAddColorBox = () => {
    if (colorName && colorHex) {
      if (colors.some((color) => color.hex === colorHex)) {
        alert('Color already added');
      } else {
        setColors([...colors, { name: colorName, hex: colorHex }]);
      }
      setColorName("");
      setColorHex("");
    } else {
      alert('Please enter both color name and hex code');
    }
  };

  const handleDeleteColorBox = (index: number) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const colorNames = colors.map((color) => color.name).join(", ");
    const colorHexCodes = colors.map((color) => color.hex).join(", ");
    const payload = {
      color_name: colorNames,
      color_hex: colorHexCodes,
    };

    try {
      const response = await fetch(
        `https://backend.inkox.com/api/inkcolors_update/${inkColorDetail.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Ink Color Updated Successfully");
        navigate('/apps/ecommerce/ink-colors-list');
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedColors = Array.from(colors);
    const [movedItem] = updatedColors.splice(result.source.index, 1);
    updatedColors.splice(result.destination.index, 0, movedItem);

    setColors(updatedColors);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    ...(isDragging && {
      transform: draggableStyle?.transform ? `${draggableStyle.transform}` : "none",
      boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
    }),
    opacity: isDragging ? 0.8 : 1, // Make sure the item is visible while dragging
  });

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Edit Ink Colors",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Edit Ink Colors"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Edit Ink Colors
                </h5>

                <Form.Group className="mb-3">
                  <Form.Label>Color Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                    placeholder="Enter color name"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Hex Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={colorHex}
                    onChange={(e) => setColorHex(e.target.value)}
                    placeholder="Enter hex code (e.g., #ff5733)"
                  />
                </Form.Group>

                <button
                  type="button"
                  className="btn w-sm btn-primary"
                  onClick={handleAddColorBox}
                >
                  Add Color Box
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {colors.length > 0 && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Added Colors
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="colors" direction="horizontal">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="color-box-container mb-3"
                          style={{
                            display: 'flex',
                            gap: '10px',
                            padding: '10px',
                            border: '1px dashed #ccc',
                            minHeight: '70px',
                          }}
                        >
                          {colors.map((color, index) => (
                            <Draggable key={color.hex} draggableId={color.hex} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100px",
                                      height: "100px",
                                      backgroundColor: color.hex,
                                      display: "inline-block",
                                      border: "1px solid #000",
                                      borderRadius: "5px",
                                      color: "black",
                                      cursor: "grab",
                                    }}
                                  >
                                    <div
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "70px",
                                        fontWeight: "bold",
                                        color: "#000",
                                      }}
                                    >
                                      {color.name}
                                    </div>
                                    {/* Delete icon */}
                                    <div
                                      className="custom-cross"
                                      onClick={() => handleDeleteColorBox(index)}
                                      style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Update
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditInkColors;
