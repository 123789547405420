import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

interface FontCategoryProps {
  revenueHistory: {
    category_icon: string;
    name: string;
    created_at: string;
    category: string;
    reference: string;
    tags: string;
    status: string;
  }[];
}

const FontCategoriesDetail = () => {

  const [revenueHistory, setRevenueHistory] = useState<FontCategoryProps["revenueHistory"]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/fontCategories");
        const data = await response.json();
        setRevenueHistory(data);
      } catch (error) {
        console.error("Error fetching clipart data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleEditClick = (item: FontCategoryProps["revenueHistory"][0]) => {
    navigate("/apps/ecommerce/edit-font-categories", { state: { item } });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Dropdown className="float-end" align="end">
            <Dropdown.Toggle as="a" className="card-drop cursor-pointer">
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Edit Report</Dropdown.Item>
              <Dropdown.Item>Export Report</Dropdown.Item>
              <Dropdown.Item>Action</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <h4 className="header-title mb-3">Font Categories Detail</h4>
          <div className="table-responsive">
            <table className="table table-borderless table-hover table-nowrap table-centered m-0">
              <thead className="table-light">
                <tr>
                  <th>Category Name</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {revenueHistory.map((item, i) => (
                <tr key={i}>
                  <td>
                  <img
                    src={item.category_icon}
                    alt={item.name}
                    style={{ maxWidth: "60px" }}
                  /><h5 className="m-0 fw-normal">{item.name}</h5>
                  </td>
                  <td>{formatDate(item.created_at)}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(item)}
                      className="btn btn-xs btn-light"
                    >
                      <i className="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default FontCategoriesDetail;
