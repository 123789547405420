import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../../../components";

interface FormValues {
  file: FileList;
  Fit?: string;
  discpounted?: string;
  mark_up_price?: string;
  byuing_price?: string;
  price?: string;
  Fabric?: string;
  metatitle?: string;
  metakeywords?: string;
  metadescription?: string;
  case_size?: string;
  youth?: string;
}

interface ProductColor {
  [color: string]: string[];
}

interface Product {
  id: number;
  productImages: string;
  name: string;
  quantity: number;
  price: number;
  productCanvasId?: string;
  productEmbrCanvasId?: string;
  productColor?: any;
  size?: any;
  brand: string; 
  vendor: string;
  productImages2: { [key: string]: string }; 
}

const ProductMerging: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productCanvases, setProductCanvases] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);
  const [selectedCanvasId, setSelectedCanvasId] = useState<any>(null);
  const [selectedCanvasId2, setSelectedCanvasId2] = useState<any>(null);
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<string>("all");
  const [canvasFilter, setCanvasFilter] = useState<string>("all");

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [productsPerPage, setProductsPerPage] = useState<number>(10);
  const [lastPage, setLastPage] = useState<number>(1);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isEmbroidery, setIsEmbroidery] = useState(false);
  // Define the indexes for slicing products based on pagination
const indexOfLastProduct = currentPage * productsPerPage;
const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

// Get the current products to display


  const pageOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    // { value: 30, label: "30" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/product-categories");
      const data = await response.json();
      const formattedCategories = data.map((category: any) => ({
        id: category.id,
        value: category.id.toString(),
        label: category.name,
      }));
      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategories = async (categoryId: any) => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/categories/product-subcategories/${categoryId}`);
      const data = await response.json();
      const formattedSubCategories = data.map((subcategory: any) => ({
        id: subcategory.id,
        value: subcategory.id.toString(),
        label: subcategory.name,
      }));
      setSubCategories(formattedSubCategories);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const fetchProductCanvases = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/tool-apparels");
      const data = await response.json();
      const formattedCanvases = data.map((canvas: any) => ({
        id: canvas.id,
        value: canvas.id.toString(),
        label: canvas.name,
      }));
      setProductCanvases(formattedCanvases);
    } catch (error) {
      console.error("Error fetching canvases:", error);
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/brand");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setBrands(["all", ...data.flat()]);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      let url = `https://backend.inkox.com/api/product?page=${currentPage}&per_page=${productsPerPage}`;
  
      if (selectedBrand !== "all") url += `&brand=${selectedBrand}`;
      if (canvasFilter !== "all") url += `&assignproduct=${canvasFilter}`;
      if (searchValue) url += `&name=${searchValue}`;
  
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  
      const data = await response.json();
  
      console.log("Fetched products for page:", currentPage, data.data); // For debugging
      setFilteredProducts(data.data || []);  // Ensure this updates with API data
      setLastPage(data.last_page || 1);      // Update pagination control
  
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  
  // Calculate products for the current page
  const currentProducts = filteredProducts; // Directly use filteredProducts
  
  useEffect(() => {
    fetchProducts(); // Fetch new data whenever page, filter, or search changes
  }, [currentPage, selectedBrand, canvasFilter, searchValue, productsPerPage]);
  
  // Pagination rendering logic, if needed, can use `lastPage` to dynamically create buttons
  

  useEffect(() => {
    fetchCategories();
    fetchProductCanvases();
    fetchBrands();
  }, [currentPage, selectedBrand, canvasFilter, searchValue, productsPerPage]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories(selectedCategory.id);
    } else {
      setSubCategories([]);
    }
  }, [selectedCategory]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const handleBrandChange = (value: string) => {
    setSelectedBrand(value);
    setCurrentPage(1);
  };

  const handleCanvasFilterChange = (value: string) => {
    setCanvasFilter(value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleProductsPerPageChange = (selectedOption: any) => {
    setProductsPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (productId: number) => {
    setSelectedProductIds((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleCardClick = (productId: number) => {
    handleCheckboxChange(productId);
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      file: yup.mixed().optional(),
      productCanvasId: yup.string().required("Product Canvas ID is required"),
    })
  );

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = new FormData();
    const file = data.file[0];

    const orderType: Partial<{ printing: boolean; embroidery: boolean }> = {};

if (isPrinting) {
  orderType.printing = true;
}
if (isEmbroidery) {
  orderType.embroidery = true;
}

    if (file) formData.append("file", file);
    if (data.Fit) formData.append("fit", data.Fit);
    if (data.discpounted) formData.append("discpounted", data.discpounted);
    if (data.mark_up_price) formData.append("mark_up_price", data.mark_up_price);
    if (data.byuing_price) formData.append("byuing_price", data.byuing_price);
    if (data.price) formData.append("price", data.price);
    if (data.Fabric) formData.append("Fabric", data.Fabric);

    if (selectedSubCategory && Array.isArray(selectedSubCategory)) {
      selectedSubCategory.forEach((subCategory) => formData.append("subCategory_id[]", subCategory?.id));
    }

    if (selectedCanvasId) formData.append("productCanvasId", selectedCanvasId?.id);
    if (selectedCanvasId2) formData.append("productEmbrCanvasId", selectedCanvasId2?.id);
    if (selectedProductIds) formData.append("selectedProductIds", JSON.stringify(selectedProductIds));
    if (selectedProductIds) formData.append("orderType", JSON.stringify(orderType));
    if (selectedProductIds.length <= 0) {
      alert("At least one Product Select");
      return false;
    }

    setIsSubmitting(true);
    try {
      // const response = await fetch("https://laravel-incox.hebesol.com/api/vendor_product_update", {
      const response = await fetch("https://backend.inkox.com/api/vendor_product_update", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const result = await response.json();
        alert("Updated Successfully");
        fetchProducts();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCardBorderColor = (product: Product) => {
    return product.productCanvasId ? "border-success" : "border-danger";
  };


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          { label: "Add Sizes", path: "/apps/ecommerce/details", active: true },
        ]}
        title={"Vendor JSON File Upload"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Vendor Product Upload</h5>

                <Form.Group className="mb-3">
                  <Form.Label>Categories</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={categories}
                    id="product-category"
                    value={selectedCategory}
                    onChange={(selectedOption) => setSelectedCategory(selectedOption)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sub Categories</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={subCategories}
                    id="product-sub-category"
                    value={selectedSubCategory}
                    onChange={(selectedOption) => setSelectedSubCategory(selectedOption)}
                    isDisabled={!selectedCategory}
                    isMulti
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Canvas Id</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={productCanvases}
                    id="product-canvas-id"
                    value={selectedCanvasId}
                    onChange={(selectedOption) => setSelectedCanvasId(selectedOption)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Product Embr Canvas Id</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={productCanvases}
                    id="product-embr-canvas-id"
                    value={selectedCanvasId2}
                    onChange={(selectedOption) => setSelectedCanvasId2(selectedOption)}
                  />
                </Form.Group>
            
                <FormInput name="discpounted" label="discpounted" placeholder="Enter discpounted" containerClass={"mb-3"} register={register} key="discpounted" />
                <FormInput name="mark_up_price" label="mark_up_price" placeholder="Enter mark_up_price" containerClass={"mb-3"} register={register} key="mark_up_price" />
                <FormInput name="byuing_price" label="byuing_price" placeholder="Enter byuing_price" containerClass={"mb-3"} register={register} key="byuing_price" />
                <FormInput name="price" label="price" placeholder="Enter price" containerClass={"mb-3"} register={register} key="price" />
                <FormInput name="Fabric" label="Fabric" placeholder="Enter Fabric" containerClass={"mb-3"} register={register} key="Fabric" />
                <FormInput name="Fit" label="Fit" placeholder="Enter Fit" containerClass={"mb-3"} register={register} key="Fit" />
                
<Form.Group className="mb-3">
  <Form.Check
    type="checkbox"
    label="Printing"
    id="printing"
    checked={isPrinting}
    onChange={(e) => setIsPrinting(e.target.checked)}
  />
</Form.Group>
<Form.Group className="mb-3">
  <Form.Check
    type="checkbox"
    label="Embroidery"
    id="embroidery"
    checked={isEmbroidery}
    onChange={(e) => setIsEmbroidery(e.target.checked)}
  />
</Form.Group>
                <div className="mb-3">
                  <label htmlFor="file" className="form-label">Size Guide Image</label>
                  <input type="file" id="file" accept="image/*" className="form-control" {...register("file")} disabled={isSubmitting} />
                </div>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Selected Product IDs</Form.Label>
                      <Form.Control type="text" value={selectedProductIds.join(", ")} readOnly />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Search Product</Form.Label>
                    <Form.Control type="text" placeholder="Search product" value={searchValue} onChange={handleSearchChange} />
                  </Col>

                  <Col>
                    <Form.Label>Brand</Form.Label>
                    <Form.Select value={selectedBrand} onChange={(e) => handleBrandChange((e.target as HTMLSelectElement).value)}>
                      {brands.map((brand, index) => (
                        <option key={index} value={brand}>
                          {brand}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>Canvas Assignment</Form.Label>
                    <Form.Select value={canvasFilter} onChange={(e) => handleCanvasFilterChange((e.target as HTMLSelectElement).value)}>
                      <option value="all">All</option>
                      <option value="assign">Assigned</option>
                      <option value="notassign">Not Assigned</option>
                    </Form.Select>
                  </Col>

                  <Col>
                    <Form.Label>Products Per Page</Form.Label>
                    <Select options={pageOptions} defaultValue={pageOptions[0]} onChange={handleProductsPerPageChange} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "15px" }}>
          {currentProducts.map((product) => {
            
            const firstImageEntry = product.productImages2
            ? Object.entries(product.productImages2)[0]
            : null;

            const vendor = product.vendor;
const isSnscanadaVendor = vendor === "snscanada";

const productImages = product.productImages2;
let imageUrl = '';

if (productImages) {
  // Handle the second approach where productImages2 contains objects for each color
  const firstColor = Object.keys(productImages)[0];
  if (firstColor && typeof productImages[firstColor] === 'object' && productImages[firstColor] !== null) {
    // Cast to unknown first, then to { front: string }
    const colorImages = productImages[firstColor] as unknown as { front: string };
    const firstImage = colorImages.front;
    imageUrl = `https://backend.inkox.com/${firstImage}`;
  }
}

            return (
            <Col className="cls" md={6} lg={3} key={product.id}>
              <Card className={`product-card ${getCardBorderColor(product)}`} style={{ border: "solid 3px" }} onClick={() => handleCardClick(product.id)}>
                <Card.Body>
                  <Form.Check
                    type="checkbox"
                    id={`product-${product.id}`}
                    checked={selectedProductIds.includes(product.id)}
                    onChange={() => handleCheckboxChange(product.id)}
                    onClick={(e) => e.stopPropagation()} // Prevent card click when checkbox clicked
                  />
                  {imageUrl ? (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={imageUrl}
                  alt="Product variant"
                  className="img-fluid"
                />
              </div>
            ) : (
              <div>No Image Available</div>
            )}
                  <h5 className="product-title">{product.name}</h5>
                  <p>Price: ${product.price}</p>
                </Card.Body>
              </Card>
            </Col>
                 );
                })}
        </Row>

        {/* Pagination Controls */}
        <Row>
  <Col className="text-center mt-4">
    <div className="pagination-controls">
      <Form.Select
        value={currentPage}
        onChange={(e) => handlePageChange(Number((e.target as HTMLSelectElement).value))}
        className="w-25 mx-auto"
      >
        {Array.from({ length: lastPage }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            Page {i + 1}
          </option>
        ))}
      </Form.Select>
    </div>
  </Col>
</Row>
      </form>
    </>
  );
};

export default ProductMerging;
