import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";

interface ImagePreview {
  part: string;
  preview: string;
}

// Modify OrderDetails to explicitly type img and imgIndex
const AssignOrderDetail = () => {
  const location = useLocation();
  const order = location.state?.order;

  if (!order) {
    return <p>No order data available</p>;
  }

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/order/details" },
          { label: "Assign Order Detail", path: "/apps/ecommerce/order/details", active: true },
        ]}
        title={`Assign Order Detail - ${order.id}`}
      />

      <Row>
      
        {/* <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Order Information</h4>
              <p><strong>Order ID:</strong> {order.id}</p>
              <p><strong>Organization:</strong> {order.nameoforganization}</p>
              <p><strong>Email:</strong> {order.emailAddress}</p>
              <p><strong>Contact:</strong> {order.firstName} {order.lastName}</p>
              <p><strong>Phone:</strong> {order.phone}</p>
              <p><strong>Order Status:</strong> {order.orderStatus}</p>
              <p><strong>Payment Status:</strong> {order.payment_status}</p>
              <p><strong>Total Amount:</strong> {order.payment_amount} {order.payment_currency}</p>
            </Card.Body>
          </Card>
        </Col> */}

        {/* Items in Order */}
        <Col lg={12}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Items</h4>
              <div className="table-responsive">
                <table className="table table-bordered table-centered mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Product Name</th>
                      <th>Product Color</th>
                      <th>Image</th>
                      <th>Clipart Images</th>
                      <th>totalQuantity</th>
                      <th>Quantity With Size</th>
                      <th>Fonts</th>
                      <th>No Of Colors</th>
                      {/* <th>Unit Price</th>
                      <th>Total Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {order.cartData.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.productName}</td>
                        <td>{item.productColor}</td>
                        <td>
                          {item.imagePreviews.map((img: ImagePreview, imgIndex: number) => (
                            <img
                              key={imgIndex}
                              src={img.preview}
                              alt={`Product ${img.part}`}
                              style={{ width: "50px", height: "50px", marginRight: "5px" }}
                            />
                          ))}
                        </td>
                        <td>
                          {item.clipArtData && item.clipArtData.length > 0 ? (
                            item.clipArtData.map((clipart: any, clipIndex: number) => (
                                <>
                                <p>{clipart.part}</p>
                              <img
                                key={clipIndex}
                                src={clipart.clipartPath}
                                alt={`Clipart ${clipart.part}`}
                                style={{ width: "50px", height: "50px", marginRight: "5px" }}
                              />
                              </>
                            ))
                          ) : (
                            <div>No clipart available</div>
                          )}
                        </td>
                        <td>{item.totalQuantity}</td>
                        <td>
          {Object.entries(item.quantities).map(([size, qty], qtyIndex) => (
            <div key={qtyIndex}>
              {size}: {qty}
            </div>
          ))}
        </td>
        <td>{item.selectedFonts}</td>
        <td>
  {item.inkColors && typeof item.inkColors === 'object' ? (
    Object.entries(item.inkColors).map(([key, colors]) => (
      <div key={key} style={{ marginBottom: '10px' }}>
        <strong>{key}:</strong>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {Array.isArray(colors) && colors.length > 0 ? (
            colors.map((color, index) => (
              <div
                key={`${key}-${index}`}
                style={{
                  display: 'inline-block',
                  width: '40px',
                  height: '40px',
                  backgroundColor: color as string, // Type assertion
                  color: '#fff',
                  textAlign: 'center',
                  lineHeight: '40px',
                  border: '1px solid #000',
                  borderRadius: '4px',
                  fontSize: '10px',
                  overflow: 'hidden',
                }}
              >
                {color as string} {/* Type assertion */}
              </div>
            ))
          ) : (
            <span>No colors available</span>
          )}
        </div>
      </div>
    ))
  ) : (
    <div>No colors available</div>
  )}
</td>


              

                      
                        {/* <td>{item.unitPrice}</td>
                        <td>{item.total_price_with_delivery}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>

        <Col lg={12}>
       <FileUploader/>  
</Col>

      </Row>
    </React.Fragment>
  );
};

export default AssignOrderDetail;