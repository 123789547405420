import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components/";

type CategoryOption = {
  value: number;
  label: string;
};

const EditClipart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state as { item: any };
  const { id } = item;

  const [categories, setCategories] = useState<CategoryOption[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryOption | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Clipart Name"),
    })
  );

  const methods = useForm({ resolver: schemaResolver, defaultValues: item });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  const onFileUpload = (files: File[]) => {
    setUploadedFiles(files);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/categories");
        const data = await response.json();
        const options: CategoryOption[] = data.map((category: any) => ({
          value: category.id,
          label: category.name,
        }));
        setCategories(options);
        const selectedOption = options.find((option: CategoryOption) => option.value === item.category.id);
        setSelectedCategory(selectedOption || null);
        setValue("category", selectedOption?.value || null);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [item, setValue]);

  const onSubmit = async (data: any) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("category", selectedCategory?.value.toString() || "");
    formData.append("reference", data.reference);
    formData.append("tags", data.tags);

    // Append the image file if it exists
    if (uploadedFiles.length > 0) {
      formData.append("image", uploadedFiles[0]);
    }

    try {
      const response = await fetch(`https://backend.inkox.com/api/cliparts/${id}`, {
        method: "POST", // Changed from PUT to POST
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Successfully updated:", result);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleCategoryChange = (selectedOption: CategoryOption | null) => {
    setSelectedCategory(selectedOption);
    setValue("category", selectedOption?.value || null);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Edit Clipart",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Edit Clipart"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5>

                <FormInput
                  name="name"
                  label="Clipart Name"
                  placeholder="Enter Clipart Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="name"
                  errors={errors}
                  control={control}
                />

                <div className="mb-3">
                  <label className="form-label">Category</label>
                  <Select
                    options={categories}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    placeholder="Select Category"
                  />
                  {errors.category && (
                    <div className="invalid-feedback d-block">
                      {errors.category.message}
                    </div>
                  )}
                </div>

                <FormInput
                  name="reference"
                  label="Reference"
                  placeholder="Enter Reference"
                  containerClass={"mb-3"}
                  register={register}
                  key="reference"
                  errors={errors}
                  control={control}
                />
                <FormInput
                  type="text"
                  rows="3"
                  name="tags"
                  label="Tags"
                  placeholder="Enter Tags"
                  containerClass={"mb-3"}
                  register={register}
                  key="tags"
                  errors={errors}
                  control={control}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Clipart Images
                </h5>
                <FileUploader onFileUpload={onFileUpload} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn w-sm btn-light waves-effect me-1"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger waves-effect waves-light me-1"
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditClipart;