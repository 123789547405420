import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import AddClipartCategories from "./AddClipartCategories"; // Import the modal form component
import "./style.css";
import AddSubCategories from "./AddSubCategory";

// Define the SubCategory type
interface SubCategory {
  id: number;
  name: string;
  category_id: string;
  icon: string;
  status: string;
  created_at: string;
  updated_at: string;
}

// Define the Category type
interface Category {
  id: number;
  name: string;
  image_path: string;
  created_at: string;
  updated_at: string;
}

const SubCategories = () => {
  const { categoryId } = useParams<{ categoryId: string }>(); // Get category ID from the URL
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [categoryName, setCategoryName] = useState<string>(""); // State to store the category name
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory | null>(null); // State for the selected subcategory
  const [itemToDelete, setItemToDelete] = useState<SubCategory | null>(null);
  const navigate = useNavigate();

  // Fetch subcategories based on the selected category
  const fetchSubCategories = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/sub-categories?category_id=${categoryId}`);
      const data = await response.json();
      setSubCategories(data.filter((sub: SubCategory) => sub.category_id.toString() === categoryId));
      // Filter subcategories by category ID
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  // Fetch the category name based on categoryId
  const fetchCategoryName = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/categories/${categoryId}`);
      const category: Category = await response.json();
      setCategoryName(category.name); // Set the category name
    } catch (error) {
      console.error("Error fetching category name:", error);
    }
  };

  useEffect(() => {
    console.log("Fetching Subcategories...");
    fetchSubCategories();
    console.log("Fetching Category Name...");
    fetchCategoryName();
  }, [categoryId]);
  
  useEffect(() => {
    console.log("Subcategories State Updated:", subCategories);
  }, [subCategories]);
  

  // Callback function to handle when a subcategory is added
  const handleSubCategoryAdded = () => {
    setShowModal(false); // Close the modal
    fetchSubCategories(); // Refresh the subcategories list
  };


  // Callback function to handle when a subcategory is added or updated
  const handleSubCategoryAddedOrUpdated = () => {
    setShowModal(false); // Close the modal
    fetchSubCategories(); // Refresh the subcategories list
    setSelectedSubCategory(null); // Clear selected subcategory after edit or add
  };

  // Handle opening the delete confirmation modal
  const handleDeleteClick = (item: SubCategory) => {
    setItemToDelete(item); // Set the subcategory to delete
    setShowDeleteModal(true); // Open the delete confirmation modal
  };

  // Handle confirming the delete action
  const confirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/subcategories/${itemToDelete.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete subcategory');
      }

      setShowDeleteModal(false); // Close the confirmation modal
      fetchSubCategories(); // Refresh the list to reflect the deleted item
      setItemToDelete(null); // Clear item to delete
    } catch (error) {
      console.error('Error deleting subcategory:', error);
    }
  };



  // Handle edit button click
  const handleEditClick = (subCategory: SubCategory) => {
    setSelectedSubCategory(subCategory); // Set the selected subcategory for editing
    setShowModal(true); // Open the modal for editing
  };

  const handleCategoryClick = (categoryId: number) => {
    navigate(`/apps/ecommerce/clipartcreate/${categoryId}`); // Navigate to subcategories page
  };
  return (
    <>
      <Card>
        <Card.Body>
          {/* Show the selected category name in the title */}
          <div className="add-new-category">
            <PageTitle
              breadCrumbItems={[
                { label: "Cliparts", path: "/apps/ecommerce/cliparts", active: true },
                { label: `${categoryName}`, path: "/apps/ecommerce/cliparts" },
              ]}
              title={`${categoryName}`} // Display the category name here
            />
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="text-lg-end mt-xl-0 mt-2">
                  <Button
                    className="btn  mb-2 me-2"
                    style={{ backgroundColor: "#1cbed9", borderColor: "#1cbed9" }}
                    onClick={() => setShowModal(true)} // Open the modal when clicked
                  >
                    <i className="mdi mdi-basket me-1"></i> Add New Sub Category
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <h4 className="header-title mb-3">Sub Categories Detail</h4>
          <div className="table-responsivess">
            <div className="abdu-cls">
              {subCategories.map((item, i) => (
                <div key={i} className="inner-clipart">
                  <img
                    src={`https://backend.inkox.com/storage/${item.icon}`}
                    className="image-clipartcategory"
                    alt={item.name}

                    onClick={() => handleCategoryClick(item.id)}
                  />
                  <div>{item.name}</div>
                  <button
                    className="btn btn-xs btn-light"
                    onClick={() => handleEditClick(item)} // Edit button click handler
                  >
                    <i className="mdi mdi-pencil"></i> Edit
                  </button>
                  <button
                    className="btn btn-xs btn-light"
                    onClick={() => handleDeleteClick(item)} // Delete button click handler
                  >
                    <i className="mdi mdi-trash-can-outline"></i> Delete
                  </button>

                  {/* Status Button */}
                  <button
                    style={{ height: "18%" }}
                    className={`badge ${item.status === "active" ? "bg-soft-success text-success" : "bg-soft-danger text-danger"}`}
                  >
                    {item.status === "active" ? "Active" : "Deactive"}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal for adding new subcategory */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedSubCategory ? 'Edit Sub Category' : 'Add New Sub Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSubCategories
            categoryId={categoryId || ""}
            categoryName={categoryName}
            onSubCategoryAdded={handleSubCategoryAddedOrUpdated}
            selectedSubCategory={selectedSubCategory} 
          />
        </Modal.Body>
      </Modal>


            {/* Confirmation Modal for Delete */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Sub Category?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubCategories;
