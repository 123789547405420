import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

interface ClipartDetailProps {
  revenueHistory: {
    id: number;
    name: string;
    created_at: string;
    category: {
      name: string;
      image_path: string;
    };
    icon: string;
    category_id: string;
    status: string;
  }[];
}

const ClipartCategoriesDetail = () => {
  const [revenueHistory, setRevenueHistory] = useState<ClipartDetailProps["revenueHistory"]>([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/sub-categories");
        const data = await response.json();
        setRevenueHistory(data);
      } catch (error) {
        console.error("Error fetching sub-categories data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleEditClick = (item: any) => {
    console.log(`Status`, item)
    navigate("/apps/ecommerce/edit-clipart-categories", {
      state: { detail: item }
    });
  };

  return (
    <Card>
      <Card.Body>
        <Dropdown className="float-end" align="end">
          <Dropdown.Toggle as="a" className="card-drop cursor-pointer">
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Edit Report</Dropdown.Item>
            <Dropdown.Item>Export Report</Dropdown.Item>
            <Dropdown.Item>Action</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <h4 className="header-title mb-3">Categories Detail</h4>
        <div className="table-responsive">
          <table className="table table-borderless table-hover table-nowrap table-centered m-0">
            <thead className="table-light">
              <tr>
                <th>Sub-Category</th>
                <th>Date</th>
                <th>Category</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(revenueHistory || []).map((item, i) => (
                <tr key={i}>
                  <td>
                    <h5 className="m-0 fw-normal">{item.name}</h5>
                  </td>

                  <td>{formatDate(item.created_at)}</td>

                  <td>{item.category.name}</td>

                  <td>
                    <span
                      className={classNames("badge", {
                        "bg-soft-warning text-warning": item.status === "",
                        "bg-soft-success text-success": item.status === "active",
                        "bg-soft-danger text-danger": item.status === "deactive",
                      })}
                    >
                      {item.status}
                    </span>
                  </td>

                  <td>
                    <button
                      onClick={() => handleEditClick(item)}
                      className="btn btn-xs btn-light"
                    >
                      <i className="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ClipartCategoriesDetail;
