import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

interface ClipartDetailProps {
  revenueHistory: {
    id: number;
    color_name: string;
    color_hex: string;
    name: string;
    created_at: string;
    category: {
      name: string;
      image_path: string;
    };
    icon: string;
    category_id: string;
    status: string;
  }[];
}
const InkColorList = () => {
  const [revenueHistory, setRevenueHistory] = useState<ClipartDetailProps["revenueHistory"]>([]);
  const navigate = useNavigate(); 

  const fetchData = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/inkcolors");
      const data = await response.json();
      setRevenueHistory(data);
    } catch (error) {
      console.error("Error fetching sub-categories data:", error);
    }
  };

  useEffect(() => {
    

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };



  const handleEditClick = (item: any) => {
    console.log(`Status`, item)
    navigate("/apps/ecommerce/EditInkColors", {
      state: { detail: item }
    });
  };
  const handleDeleteClick = async (id: any) => {
    if (window.confirm('Are you sure you want to delete this Ink Color?')) {
      try {
        // Send DELETE request to your API
        const response = await fetch(`https://backend.inkox.com/api/inkcolors_delete/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Check if the response is successful
        if (response.ok) {
          alert('Ink Color deleted successfully');
          fetchData();
        } else {
          throw new Error('Failed to delete the clipart');
        }
      } catch (error) {
        console.error('There was an error deleting the clipart!', error);
        alert('Failed to delete the clipart');
      }
    }
  };
  return (
    <Card>
      <Card.Body>
        <Dropdown className="float-end" align="end">
          <Dropdown.Toggle as="a" className="card-drop cursor-pointer">
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Edit Report</Dropdown.Item>
            <Dropdown.Item>Export Report</Dropdown.Item>
            <Dropdown.Item>Action</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <h4 className="header-title mb-3">Ink Color List</h4>
        <div className="table-responsive">
          <table className="table table-borderless table-hover table-nowrap table-centered m-0">
            <thead className="table-light">
              <tr>
                <th>Color Names</th>
                <th>No. Of Colors</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {(revenueHistory || []).map((item, i) => (
                <tr key={i}>
                   <td>
                    {item.color_name}
                  </td>
                   <td>
                    {item.color_hex.split(', ').map((color, index) => (
                      <span key={index} style={{ backgroundColor: color, display: 'inline-block', width: '20px', height: '20px', marginRight: '5px', borderRadius: '3px' }}>
                        {/* Optional: Tooltip or accessible text */}
                        <span className="sr-only">{color}</span>
                      </span>
                    ))}
                  </td>

                  <td>
                      <button
                        onClick={() => handleEditClick(item)}
                        className="btn btn-xs btn-light"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                    </td>
                    <td>
                    <button
                      onClick={() => handleDeleteClick(item.id)} // Call delete function here
                      className="btn btn-xs btn-light"
                    >
                      <i className="mdi mdi-trash-can-outline"></i>
                    </button>
                    </td>


                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InkColorList;
