import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

interface FormValues {
  name: string;
}

interface LocationState {
  detail: {
    id: number;
    size_name: string;
    size: string;
  };
}

interface SizeItemProps {
  size: string;
  index: number;
  moveSize: (dragIndex: number, hoverIndex: number) => void;
  handleDeleteSize: (index: number) => void;
}

const ItemType = "SIZE";

const SizeItem: React.FC<SizeItemProps> = ({ size, index, moveSize, handleDeleteSize }) => {
  const [, ref] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveSize(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
  });

  return (
    <div
      ref={(node) => drag(ref(node))}
      style={{
        position: "relative",
        padding: "10px",
        backgroundColor: "#f5f5f5",
        display: "inline-block",
        border: "1px solid #000",
        borderRadius: "4px",
        margin: "5px",
        cursor: "move",
      }}
    >
      {size}
      <div
        className="custom-cross"
        onClick={() => handleDeleteSize(index)}
        style={{
          position: "absolute",
          top: "-10px",
          right: "-10px",
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      >
        X
      </div>
    </div>
  );
};

const EditSize: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { detail } = (location.state as LocationState) || {};

  const [productCanvasId, setProductCanvasId] = useState<string>(detail?.size_name || "");
  const [size, setSize] = useState<string>("");
  const [sizes, setSizes] = useState<string[]>([]);

  useEffect(() => {
    if (detail) {
      setProductCanvasId(detail.size_name);
      setSizes(detail.size.split(", "));
    }
  }, [detail]);

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const handleProductCanvasIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductCanvasId(e.target.value);
  };

  const handleAddSize = () => {
    if (size) {
      if (sizes.includes(size)) {
        window.alert("Already Added"); // Show alert if size already exists
      } else {
        setSizes([...sizes, size]);
        setSize(""); // Clear input after adding
      }
    }
  };

  const handleDeleteSize = (index: number) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
  };

  const moveSize = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedSizes = [...sizes];
      const [movedSize] = updatedSizes.splice(dragIndex, 1);
      updatedSizes.splice(hoverIndex, 0, movedSize);
      setSizes(updatedSizes);
    },
    [sizes]
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const sizesCommaSeparated = sizes.join(", ");

    const payload = {
      size_name: productCanvasId,
      size: sizesCommaSeparated,
    };

    try {
      const response = await fetch(`https://backend.inkox.com/api/sizes/${detail?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Update Size Successfully");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteSize = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this size?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/sizes_delete/${detail?.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        alert('Size deleted successfully');
      } else {
        alert('Failed to delete size');
      }
    } catch (error) {
      alert('An error occurred while deleting the size.');
      console.error(error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Edit Sizes",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Edit Sizes"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Edit Sizes</h5>
                <FormInput
                  name="name"
                  label="Id"
                  placeholder="Enter ID"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  value={productCanvasId}
                  onChange={handleProductCanvasIdChange}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Enter Size</Form.Label>
                  <Form.Control
                    type="text"
                    value={size}
                    placeholder="Enter size"
                    onChange={(e) => setSize(e.target.value)}
                  />
                </Form.Group>
                <button
                  type="button"
                  className="btn w-sm btn-primary"
                  onClick={handleAddSize}
                >
                  Add Size
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {sizes.length > 0 && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Added Sizes (Drag to reorder)
                  </h5>

                  <DndProvider backend={HTML5Backend}>
                    <div className="size-box-container mb-3" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                      {sizes.map((size, index) => (
                        <SizeItem
                          key={index}
                          size={size}
                          index={index}
                          moveSize={moveSize}
                          handleDeleteSize={handleDeleteSize}
                        />
                      ))}
                    </div>
                  </DndProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Update
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger waves-effect waves-light me-1"
                onClick={deleteSize}
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditSize;
