import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { json, useLocation } from "react-router-dom";
// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// components
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";

interface CategoryOption {
  value: string;
  label: string;
}

interface FileType extends File {
  preview?: string;
  formattedSize?: string;
}

const EditCategories = () => {
  const { state } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<File | null>(null); // Icon for Category
  const [selectedSubCategoryIcon, setSelectedSubCategoryIcon] = useState<File | null>(null); // Icon for Sub Category
  const [categoryIcon, setCategoryIcon] = useState<FileType[]>([]);
  const [subCategoryIcon, setSubCategoryIcon] = useState<FileType[]>([]);

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Category Name"),
      description: yup.string().required("Please enter Category Description"),
      reference: yup.string().required("Please enter Sub Category Name"),
      // proddescription: yup.string().required("Please enter Sub Category Description"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  /**
   * Fetch categories from the API and set options for the Select field
   */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/product-categories");
        const data = await response.json();

        const options: CategoryOption[] = data.map((category: any) => ({
          value: category.id,
          label: category.name,
        }));

        setCategoryOptions(options);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  /**
   * Load the passed data from state and map to form fields
   */
  useEffect(() => {
    if (state && state.detail) {
      const { detail } = state;
      setValue("name", detail.category.name);
    setValue("description", detail.category.description);
    setSelectedCategory(detail.category_id);
    setValue("reference", detail.name);
    // setValue("proddescription", detail.description);
    }
  }, [state, setValue]);


  /**
   * Handle category update
   */
  const handleCategoryUpdate = async (formData: any) => {
    try {
        const categoryFormData = new FormData();

        // Convert checkbox value to string ("true" or "false")
        const trendingCategoryString = formData.trending_category ? "true" : "false";

        // Append text data (name)
        categoryFormData.append("name", formData.name);
        categoryFormData.append("description", formData.description);
        // Append file data if icon is selected
        if (categoryIcon.length > 0) {
            categoryFormData.append('icon', categoryIcon[0]);
        }

        // Append trending_category as a string ("true" or "false")
        categoryFormData.append("trending_category", trendingCategoryString);

        const response = await fetch(
            `https://backend.inkox.com/api/product-categories/${state.detail.category.id}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: categoryFormData,
            }
        );

        if (response.ok) {
            const data = await response.json();
            console.log("Category updated:", data);
        } else {
            console.error("Failed to update category");
        }
    } catch (error) {
        console.error("Error updating category:", error);
    }
};






  /**
   * Handle sub category update
   */
  const handleSubCategoryUpdate = async (formData: any) => {
    try {
      const subCategoryFormData = new FormData();
      console.log("Data", selectedCategory)
      // Append text data (reference) 
      subCategoryFormData.append("name", formData.reference);
      // subCategoryFormData.append("description", formData.proddescription);
      subCategoryFormData.append('category_id', JSON.stringify(selectedCategory));

      // Append file data if icon is selected
      if (subCategoryIcon.length > 0) {
        subCategoryFormData.append('icon', subCategoryIcon[0]);
      }

      const response = await fetch(
        `https://backend.inkox.com/api/product-subcategories/${state.detail.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: subCategoryFormData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Sub Category updated:", data);
      } else {
        console.error("Failed to update sub category");
      }
    } catch (error) {
      console.error("Error updating sub category:", error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Categories", path: "/apps/ecommerce/categories-detail" },
          {
            label: "Edit Categories",
            path: "/apps/ecommerce/categories-detail",
            active: true,
          },
        ]}
        title={"Edit Categories"}
      />

      {/* Form for Edit Category */}
      <form onSubmit={handleSubmit(handleCategoryUpdate)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Edit Category
                </h5>
                <FormInput
                  name="name"
                  label="Category Name"
                  placeholder="Enter Category Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                />
                    <FormInput
                  type="textarea"
                  rows="5"
                  name="description"
                  label="Enter Description"
                  placeholder="Please enter description"
                  containerClass={"mb-3"}
                  register={register}
                  key="description"
                  errors={errors}
                />
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Category Images
                </h5>

                {/* FileUploader component for icon upload */}
                <FileUploader onFileUpload={(files) => setCategoryIcon(files)} />

                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Trending Category
                </h5>

                <Form.Group className="mb-3">
  <Form.Check
    type="checkbox"
    id="trending_category"
    {...register('trending_category')} // Register the checkbox with React Hook Form
    isInvalid={!!errors.trending_category} // Show validation error if needed
  />
  {errors.trending_category && (
    <Form.Control.Feedback type="invalid">
      {errors.trending_category.message}
    </Form.Control.Feedback>
  )}
</Form.Group>


                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="btn w-sm btn-light waves-effect me-1"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn w-sm btn-success waves-effect waves-light me-1"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn w-sm btn-danger waves-effect waves-light me-1"
                      >
                        Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* Form for Edit Sub Category */}
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Edit Sub Category
                </h5>
                <Form.Group className="mb-3">
                  <Form.Label>Assign Main Category</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={categoryOptions}
                    value={categoryOptions.find((option) => option.value === selectedCategory)}
                    id="product-category"
                    onChange={(selected) => setSelectedCategory(selected?.value ?? null)}
                  />
                </Form.Group>

                <FormInput
                  name="reference"
                  label="Sub Category Name"
                  placeholder="Enter Sub Category"
                  containerClass={"mb-3"}
                  register={register}
                  key="reference"
                  errors={errors}
                />
            {/* <FormInput
                  type="textarea"
                  rows="5"
                  name="proddescription"
                  label="Enter Description"
                  placeholder="Please enter description"
                  containerClass={"mb-3"}
                  register={register}
                  key="proddescription"
                  errors={errors}
                /> */}
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Sub Category Images
                </h5>

                <FileUploader onFileUpload={(files) => setSubCategoryIcon(files)} />

                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="btn w-sm btn-light waves-effect me-1"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn w-sm btn-success waves-effect waves-light me-1"
                        onClick={handleSubmit(handleSubCategoryUpdate)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn w-sm btn-danger waves-effect waves-light me-1"
                      >
                        Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditCategories;
