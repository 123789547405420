import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Replace useHistory with useNavigate
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";
import PageTitle from "../../../components/PageTitle";

const EditFontCategories = () => {
  const { state } = useLocation(); // Get the state from location
  const navigate = useNavigate(); // Use useNavigate for navigation
  const { item } = state || {}; // Destructure the item passed from FontCategoriesDetail
  const [fontFile, setFontFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null); // State to store file name
  const [defaultCategoryIcon, setDefaultCategoryIcon] = useState<string | null>(null);

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
    })
  );

  /*
   * form methods
   */
  const methods = useForm({
    resolver: schemaResolver,
    defaultValues: {
      name: item?.name || "", // Pre-fill category name
      reference: item?.reference || "", // Pre-fill reference
    },
  });

  const handleFileUpload = (files: File[]) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString();
        setFontFile(base64String || null);
      };
      reader.readAsDataURL(files[0]);

      // Store the uploaded file's name
      setFileName(files[0].name);
    }
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (item) {
      // Set default values when item is available
      setValue("name", item.name);
      setValue("reference", item.reference);
      setDefaultCategoryIcon(item.category_icon); // Set the default category icon if needed
    }
  }, [item, setValue]);

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        category_icon: fontFile,
        file_name: fileName || "default-file-name.jpg", // Set the file name here
      };

      const response = await fetch(`https://backend.inkox.com/api/font-categories/${item?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Navigate back to the categories list after a successful update
        console.log("response",response)
        // navigate("/apps/ecommerce/categories-detail");
      }
    } catch (error) {
      console.error("Error updating font category:", error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Categories", path: "/apps/ecommerce/categories-detail" },
          {
            label: "Edit Font Categories",
            path: "/apps/ecommerce/categories-detail",
            active: true,
          },
        ]}
        title={"Edit Font Categories"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Edit Category
                </h5>
                <FormInput
                  name="name"
                  label="Category Name"
                  placeholder="Enter Category Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="categoryname"
                  errors={errors}
                  control={control}
                />
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Category Icon
                </h5>
                <FileUploader onFileUpload={handleFileUpload} />
                
                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="btn w-sm btn-light waves-effect me-1"
                        // onClick={() => navigate("/apps/ecommerce/categories-detail")}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn w-sm btn-success waves-effect waves-light me-1"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn w-sm btn-danger waves-effect waves-light me-1"
                        onClick={() => {/* Add delete functionality if needed */}}
                      >
                        Delete
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditFontCategories;