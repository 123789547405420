    import React, { useState, useEffect } from "react";
    import { Row, Col, Card, Form } from "react-bootstrap";
    import { useForm, Controller } from "react-hook-form";
    import { useLocation } from "react-router-dom";
    import * as yup from "yup";
    import { yupResolver } from "@hookform/resolvers/yup";
    import Select from "react-select";
    import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
    import PageTitle from "../../../components/PageTitle";
    import FileUploader from "../../../components/FileUploader";
    import { FormInput } from "../../../components";

    interface FileType extends File {
      preview?: string;
      formattedSize?: string;
    }

    interface CategoryOption {
      id: number;
      value: string;
      label: string;
    }

    const EditClipartCategories = () => {
      const location = useLocation();
      const detail = location.state?.detail;

      const [selectedCategory, setSelectedCategory] = useState<CategoryOption | null>(null);

      const [categoryIcon, setCategoryIcon] = useState<FileType[]>([]);
      const [categoryName, setCategoryName] = useState<any[]>([]);

      useEffect(() => {
        if (detail?.image_path) {
          const existingIcon: FileType = {
            name: "existing",
            preview: "https://backend.inkox.com/storage/"+detail.image_path,
            size: 0,
            type: "",
            lastModified: 0,
            arrayBuffer: async () => new ArrayBuffer(0),
            slice: (start?: number, end?: number, contentType?: string) => new Blob([], { type: contentType }),
            stream: () => new ReadableStream(),
            text: async () => "",
            webkitRelativePath: "",
          };
          setCategoryIcon([existingIcon]);
        }
        setCategoryName(detail.name);
      }, [detail]);

      console.log("categoryIcon",categoryIcon);
      console.log("detail",detail);
      // const [categories, setCategories] = useState<CategoryOption[]>([]);

      // const fetchCategories = async () => {
      //   try {
      //     const response = await fetch('https://laravel-incox.hebesol.com/api/categories');
      //     const data = await response.json();
      //     const formattedCategories = data.map((category: any) => ({
      //       id: category.id,
      //       value: category.id.toString(),
      //       label: category.name,
      //     }));
      //     setCategories(formattedCategories);

      //     if (detail) {
      //       const selected = formattedCategories.find(
      //         (category: CategoryOption) => category.id === parseInt(detail.category_id)
      //       );
      //       setSelectedCategory(selected || null);
      //     }
      //   } catch (error) {
      //     console.error('Error fetching categories:', error);
      //   }
      // };

      // useEffect(() => {
      //   fetchCategories();
      // }, []);

      const schemaResolver = yupResolver(
        yup.object().shape({
          name: yup.string().required("Category name is required"),
          // reference: yup.string().required("Subcategory name is required"),
          // mainCategory: yup
          //   .object()
          //   .nullable()
          //   .required("Main category is required"),
        })
      );

      const methods = useForm({
        resolver: schemaResolver,
        defaultValues: {
          // reference: detail?.name || "",
          name: detail?.name || "", // Set default value from detail
          // mainCategory: detail?.category_id ? { id: detail.category_id, value: detail.category_id.toString(), label: detail.name } : null, // Set default value from detail
        },
      });

      const {
        handleSubmit,
        register,
        control,
        formState: { errors },
      } = methods;


      const handleCategoryUpdate = async (data: any) => {
        console.log('Form data:', data);
        console.log('Errors:', errors); // Check for validation errors
        if (!detail?.id) {
          console.error('Category ID is missing');
          return;
        }

        const formData = new FormData();
        formData.append('name', data.name);
      
        if (categoryIcon.length > 0 && categoryIcon[0].name !== "existing") {
          formData.append('image', categoryIcon[0]);
        }
        console.log("formData",formData);
        try {
          // const response = await fetch(`https://laravel-incox.hebesol.com/api/categories/${detail.category.id}`, {
          const response = await fetch(`https://backend.inkox.com/api/categories/${detail.category.id}`, {
            method: 'POST',
            body: formData,
            headers: {
              'X-HTTP-Method-Override': 'PUT',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to update category');
          }

          const updatedCategory = await response.json();
          console.log("Category updated successfully:", updatedCategory);
          
        } catch (error) {
          console.error('Error updating category:', error);
        }
      };

      const onSubmit = async (data: any) => {
        console.log('Form submitted:', data);
        await handleCategoryUpdate(data);
      };
      return (
        <>
          <PageTitle
            breadCrumbItems={[
              { label: "Categories", path: "/apps/ecommerce/categories-detail" },
              {
                label: "Add Clipart Categories",
                path: "/apps/ecommerce/categories-detail",
                active: true,
              },
            ]}
            title={"Add Clipart Categories"}
          />
        
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Edit Category
                    </h5>
                    <FormInput
                      name="name"
                      label="Category Name"
                      placeholder="Enter Category Name" 
                      containerClass={"mb-3"}
                      register={register}
                      key="productname"
                      errors={errors}
                      control={control}
                      defaultValue={categoryName}
                    />
                    <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                      Category Icon
                    </h5>
                    <FileUploader
                      onFileUpload={(files) => setCategoryIcon(files)}
                    />
                    {categoryIcon.length > 0 && categoryIcon[0].preview && (
                    <div>
                      <img
                        src={categoryIcon[0].preview}
                        alt="Category Icon Preview"
                        style={{ maxWidth: '50%', marginTop: '10px' }}
                      />
                    </div>
                  )}
                    <Row>
                      <Col>
                        <div className="text-center mb-3">
                          <button
                            type="button"
                            className="btn w-sm btn-light waves-effect me-1"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn w-sm btn-success waves-effect waves-light me-1"
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn w-sm btn-danger waves-effect waves-light me-1"
                          >
                            Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              
            </Row>
          </form>
        </>
      );
    };

    export default EditClipartCategories;