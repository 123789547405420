import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import "./style.css";
import FontCategories from "./FontCategories";

interface FontCategoryProps {
  id: number;
  category_icon: string;
  name: string;
  created_at: string;
  category: string;
  reference: string;
  tags: string;
  status: string;
}

const Fonts = () => {
  const [fontCategories, setFontCategories] = useState<FontCategoryProps[]>([]);
  const [status, setStatus] = useState<{ [key: number]: string }>({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFontCategory, setSelectedFontCategory] = useState<FontCategoryProps | null>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<FontCategoryProps | null>(null);
  const navigate = useNavigate();

  // Fetch font categories from the API
  const fetchData = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/fontCategories");
      const data = await response.json();

      const initialStatus = data.reduce((acc: any, item: FontCategoryProps, index: number) => {
        acc[index] = item.status || "active";
        return acc;
      }, {});

      setFontCategories(data);
      setStatus(initialStatus);
    } catch (error) {
      console.error("Error fetching font categories data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCategoryClick = (categoryId: number) => {
    navigate(`/apps/ecommerce/fonts/${categoryId}`);
  };

  const toggleStatus = (index: number) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [index]: prevStatus[index] === "active" ? "deactive" : "active",
    }));
  };

  // Handle edit button click
  const handleEditClick = (category: FontCategoryProps) => {
    setSelectedFontCategory(category);
    setShowModal(true);
  };

  // Handle delete button click
  const handleDeleteClick = (category: FontCategoryProps) => {
    setCategoryToDelete(category);
    setShowDeleteModal(true);
  };

  // Confirm delete action
  const confirmDelete = async () => {
    if (!categoryToDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/fontCategories/${categoryToDelete.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete font category');
      }

      setFontCategories((prev) => prev.filter((cat) => cat.id !== categoryToDelete.id));
      setShowDeleteModal(false);
      setCategoryToDelete(null);
    } catch (error) {
      console.error('Error deleting font category:', error);
    }
  };

  const handleCategoryAddedOrUpdated = (updatedCategory: FontCategoryProps) => {
    if (selectedFontCategory) {
      // Update the existing category in the state
      setFontCategories((prevCategories) =>
        prevCategories.map((cat) =>
          cat.id === updatedCategory.id
            ? {
                ...updatedCategory,
                // Append a random query parameter to the image URL to prevent caching
                category_icon: `https://backend.inkox.com/storage/${updatedCategory.category_icon}`,
              }
            : cat
        )
      );
    } else {
      // Add a new category to the state
      setFontCategories((prevCategories) => [
        ...prevCategories,
        {
          ...updatedCategory,
          // Append a random query parameter to the image URL to prevent caching
          category_icon: `https://backend.inkox.com/storage/${updatedCategory.category_icon}`,
        },
      ]);
    }
  
    // Close the modal and reset the selected category
    setShowModal(false);
    setSelectedFontCategory(null);
  };
  

  return (
    <>
      <Card>
        <Card.Body>
          <div className="add-new-category">
            <PageTitle
              breadCrumbItems={[
                { label: "Fonts", path: "/apps/ecommerce/fonts" },
              ]}
              title={"Fonts"}
            />

            <Row className="align-items-center">
              <Col lg={12}>
                <div className="text-lg-end mt-xl-0 mt-2">
                  <Button
                    className="btn mb-2 me-2"
                    style={{ backgroundColor: "#1cbed9", borderColor: "#1cbed9" }}
                    onClick={() => {
                      setSelectedFontCategory(null);
                      setShowModal(true);
                    }}
                  >
                    <i className="mdi mdi-basket me-1"></i> Add New Category
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          <h4 className="header-title mb-3">Font Categories Detail</h4>
          <div className="table-responsivess">
            <div className="abdu-cls">
              {fontCategories.length > 0 ? (
                fontCategories.map((item, i) => (
                  <div key={i} className="inner-clipart">
                    <img
                      src={item.category_icon}
                      className="image-clipartcategory"
                      alt={item.name}
                      onClick={() => handleCategoryClick(item.id)}
                      style={{ cursor: "pointer" }}
                    />
                    <div>{item.name}</div>
                    <button
                      className="btn btn-xs btn-light"
                      onClick={() => handleEditClick(item)}
                    >
                      <i className="mdi mdi-pencil"></i> Edit
                    </button>
                    <button
                      className="btn btn-xs btn-light"
                      onClick={() => handleDeleteClick(item)}
                    >
                      <i className="mdi mdi-trash-can-outline"></i> Delete
                    </button>

                    {/* Status Button */}
                    <button
                      onClick={() => toggleStatus(i)}
                      style={{ height: "18%" }}
                      className={classNames("badge", {
                        "bg-soft-success text-success": status[i] === "active",
                        "bg-soft-danger text-danger": status[i] === "deactive",
                      })}
                    >
                      {status[i] === "active" ? "Active" : "Deactive"}
                    </button>
                  </div>
                ))
              ) : (
                <p>No categories found.</p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal for adding/editing category */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedFontCategory ? "Edit Category" : "Add New Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FontCategories
            selectedFontCategory={selectedFontCategory}
            onCategoryAddedOrUpdated={handleCategoryAddedOrUpdated}
          />
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal for Delete */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Fonts;
