import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploader from "../../../components/FileUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormData {
  name: string;
  sub_category: string; // Update this to string
  reference: string;
  tags: string;
}

interface Clipart {
  id: number;
  name: string;
  image?: File | string;
  sub_category: string;
  icon: string;
  status: string;
  created_at: string;
  updated_at: string;
  tags?: string; // Make optional
  reference?: string; // Make optional
}


interface AddClipartProps {
  subCategoryName: string;
  subCategoryId: string;
  selectedClipart?: any;
  onClipartAdded: (clipart: any) => void; // Callback function when clipart is added
}

const AddClipart: React.FC<AddClipartProps> = ({ subCategoryName, subCategoryId, onClipartAdded, selectedClipart }) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Clipart Name"),
      reference: yup.string().required("Please enter Reference"),
      tags: yup.string().required("Please enter Tags"),
    })
  );

  const methods = useForm<FormData>({
    resolver: schemaResolver,
    defaultValues: {
      name: selectedClipart?.name || "",
      reference: selectedClipart?.reference || "",
      tags: selectedClipart?.tags || "",
    },
  });

  const { handleSubmit, register, setValue, formState: { errors } } = methods;

  useEffect(() => {
    if (selectedClipart) {
      setValue("name", selectedClipart.name);
      setValue("reference", selectedClipart.reference || ""); // Provide default value if `reference` is missing
      setValue("tags", selectedClipart.tags || ""); // Provide default value if `tags` is missing
      if (selectedClipart.image_path) {
        setUploadedFiles([{
          name: "existing",
          preview: selectedClipart.image_path,  // Assuming 'icon' is the image path
          size: 0,
          type: "",
          lastModified: 0,
          webkitRelativePath: "", // Mock value
          arrayBuffer: async () => new ArrayBuffer(0), // Mock function
          slice: () => new Blob([], { type: "" }), // Mock function
          stream: () => new ReadableStream(), // Mock function
          text: async () => "", // Mock function
        }]);
      }
    } else {
      setValue("name", "");
      setValue("reference", "");
      setValue("tags", "");
      setUploadedFiles([]);
    }
  }, [selectedClipart, setValue]);

  const onFileUpload = (files: any[]) => {
    setUploadedFiles(files);
  };

  const onSubmit = async (data: FormData) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('sub_category', subCategoryId);
    formData.append('reference', data.reference);
    formData.append('tags', data.tags);
  
    if (uploadedFiles.length > 0 && uploadedFiles[0].name !== "existing") {
      formData.append('image', uploadedFiles[0]);
    }
  
    try {
      const url = selectedClipart
        ? `https://backend.inkox.com/api/cliparts/${selectedClipart.id}`
        : 'https://backend.inkox.com/api/cliparts';
  
      const method = selectedClipart ? 'POST' : 'POST'; // Both methods use POST but PUT override for update
  
      // Only include headers if editing an existing clipart
      const headers = selectedClipart
        ? { 'X-HTTP-Method-Override': 'PUT' }
        : undefined;
  
      const response = await fetch(url, {
        method: method,
        body: formData,
        headers: headers as HeadersInit | undefined, // Use type assertion to satisfy TypeScript
      });
  
      if (response.ok) {
        const result = await response.json();
        onClipartAdded(result); // Trigger the callback with the new or updated clipart
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      toast.error("Error saving clipart!", { position: "bottom-right" });
    }
  };
  
  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>

                <Form.Group className="mb-3">
                  <Form.Label>Clipart Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Clipart Name"
                    {...register("name")}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sub-category</Form.Label>
                  <Form.Control
                    type="text"
                    value={subCategoryName} // Show subCategoryName
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Reference</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Reference"
                    {...register("reference")}
                    isInvalid={!!errors.reference}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.reference?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Tags"
                    {...register("tags")}
                    isInvalid={!!errors.tags}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tags?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Clipart Images</h5>
                <FileUploader onFileUpload={onFileUpload} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <button type="submit" className="btn btn-success">Save</button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddClipart;
