import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";

interface FileType extends File {
  preview?: string;
  formattedSize?: string;
}

interface CategoryOption {
  id: number;
  value: string;
  label: string;
}

interface AddClipartCategoriesProps {
  onCategoryCreated: () => void; // Add prop for category creation callback
  selectedItem?: any; // Add prop for the selected item for editing
}

const AddClipartCategories: React.FC<AddClipartCategoriesProps> = ({ onCategoryCreated, selectedItem }) => {
  const [categoryIcon, setCategoryIcon] = useState<FileType[]>([]);
  
  const { handleSubmit, register, setValue, reset, control, formState: { errors } } = useForm({
    defaultValues: {
      name: "", // Default to empty for new category
    },
  });
  // Pre-fill the form if editing
  useEffect(() => {
    if (selectedItem) {
      // Editing an existing category, so fill the form with data
      setValue('name', selectedItem.name);
      if (selectedItem.image_path) {
        setCategoryIcon([{
          name: "existing",
          preview: selectedItem.image_path,
          size: 0,
          type: "",
          lastModified: 0,
          webkitRelativePath: "",
          arrayBuffer: async () => new ArrayBuffer(0),
          slice: () => new Blob([]),
          stream: () => new ReadableStream(),
          text: async () => "",
        }]);
      }
    } else {
      // Creating a new category, so reset the form
      reset({
        name: "", // Clear the form fields
      });
      setCategoryIcon([]); // Clear any previous icons
    }
  }, [selectedItem, setValue, reset]);
  const handleCategorySave = async (data: any) => {
    const formData = new FormData();
    formData.append('name', data.name);

    if (categoryIcon.length > 0 && categoryIcon[0].name !== "existing") {
      formData.append('image', categoryIcon[0]); // Add new image only if it has been changed
    }

    try {
      // If selectedItem exists, we're editing an existing category
      const url = selectedItem
        ? `https://backend.inkox.com/api/categories/${selectedItem.id}`
        : `https://backend.inkox.com/api/categories`;

      const method = selectedItem ? 'POST' : 'POST'; // Laravel uses 'POST' with 'PUT' override for updates

      const response = await fetch(url, {
        method: method,
        body: formData,
        headers: {
          'X-HTTP-Method-Override': selectedItem ? 'PUT' : 'POST', // Override to 'PUT' for updates
        },
      });

      if (!response.ok) {
        throw new Error('Error saving category');
      }

      const result = await response.json();
      console.log('Category saved:', result);
      
      // Trigger the callback to close the modal and refresh the list
      onCategoryCreated();

    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCategorySave)}>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <FormInput
                name="name"
                label="Category Name"
                placeholder="Enter Category Name"
                containerClass={"mb-3"}
                register={register}
                key="productname"
                errors={errors}
                control={control}
              />
              <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                Category Icon
              </h5>
              <FileUploader
                onFileUpload={(files) => setCategoryIcon(files)}
              />
              
              {/* Show existing image if editing and no new image is uploaded */}
              {categoryIcon.length === 0 && selectedItem?.icon && (
                <div>
                  <img
                    src={selectedItem.icon}
                    alt="Existing Category Icon"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                </div>
              )}

              <Row>
                <Col>
                  <div className="text-center mb-3">
                    <button
                      type="button"
                      className="btn w-sm btn-light waves-effect me-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn w-sm btn-success waves-effect waves-light me-1"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default AddClipartCategories;
