import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import FileUploader from "../../../components/FileUploader";
import './style.css';
import { useLocation, useNavigate } from "react-router-dom";


interface Product {
  id: number;
  name: string;
  description: string;
  productColor: string;
  productSize: string[];
}

interface GuideBox {
  guidelineBoxText: string;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
}

interface Part {
  name: string;
  [key: string]: any;
  colors: string[];
}

interface FormValues {
  name: string;
  parts: Part[];
}

const EditToolApparel: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [parts, setParts] = useState<Part[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productColors, setProductColors] = useState<string[]>([]);
  const [screen, setScreen] = useState<boolean>(false);
  const [emb, setEmb] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = Boolean(location.state?.detail);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Project Name"),
    })
  );

  const methods = useForm<FormValues>({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedOption === "Images") {
      fetchProducts();
    }
  }, [selectedOption]);

  const fetchProducts = async () => {
    try {
      const response = await fetch("https://backend.inkox.com/api/product");
      const data = await response.json();
      setProducts(data.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      const detail = location.state.detail;
      setProductCanvasId(detail.name);
      setValue("name", detail.name);
  
      try {
        const parsedParts = JSON.parse(detail.parts);
        const updatedParts = parsedParts.map((part: any) => {
          const description = JSON.parse(part.description || "{}");
  
          return {
            ...part,
            ...description,
            screenPrintingData: {
              ...description.screenPrintingData,
              [`DesignAreaComponent${parsedParts.indexOf(part) + 1}`]:
                description.screenPrintingData?.[`DesignAreaComponent${parsedParts.indexOf(part) + 1}`] || {
                  designAreaText: "",
                  designableArea: {
                    leftPercent: 0,
                    topPercent: 0,
                    widthPercent: 0,
                    heightPercent: 0,
                  },
                  guideboxes: [],
                },
            },
            embroideryData: {
              ...description.embroideryData,
              [`DesignAreaComponent${parsedParts.indexOf(part) + 1}`]:
                description.embroideryData?.[`DesignAreaComponent${parsedParts.indexOf(part) + 1}`] || {
                  designAreaText: "",
                  designableArea: {
                    leftPercent: 0,
                    topPercent: 0,
                    widthPercent: 0,
                    heightPercent: 0,
                  },
                  guideboxes: [],
                },
            },
          };
        });
  
        setParts(updatedParts);
        setSelectedOption(detail.type || "");
      } catch (error) {
        console.error("Error parsing parts data:", error);
      }
    }
  }, [isEditMode, location.state, setValue]);
  

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    setParts([]);
    setSelectedProduct(null);
    setProductColors([]);
  };

  const propagateColorsToParts = (colors: string[]) => {
    setParts((prevParts) =>
      prevParts.map((part) => ({
        ...part,
        colors,
      }))
    );
  };

  const handleProductSelect = (productId: number) => {
    const product = products.find((p) => p.id === productId) || null;
    setSelectedProduct(product);
    if (product) {
      const colors = Object.keys(JSON.parse(product.productColor || "{}"));
      setProductColors(colors);
      propagateColorsToParts(colors);
    }
  };

  const handleCheckboxChange = (
    partIndex: number,
    option: "screenPrinting" | "embroidery"
  ) => {
    if(option == "screenPrinting") {
      setScreen(true);
    } else if (option == "embroidery") {
      setEmb(true);
    } else {
      setScreen(true);
      setEmb(true);
    }

    setParts((prevParts) =>
      prevParts.map((part, index) =>
        index === partIndex
          ? { ...part, [option]: !part[option] }
          : part
      )
    );
  };

  // const handleAddPart = () => {
  //   const partIndex = parts.length + 1; // Increment part index based on current parts
  //   setParts([
  //     ...parts,
  //     {
  //       name: `Part ${partIndex}`,
  //       screenPrinting: false,
  //       embroidery: false,
  //       [`ProductImageComponent${partIndex}`]:
  //         selectedOption === "Masking"
  //           ? { image: "" }
  //           : { colors: [] },
  //       screenPrintingData: {
  //         [`CanvasComponent${partIndex}`]: {
  //           canvasSize: "dynamic",
  //         },
  //         [`DesignAreaComponent${partIndex}`]: {
  //           designAreaText: "",
  //           designableArea: {
  //             leftPercent: 0,
  //             topPercent: 0,
  //             widthPercent: 0,
  //             heightPercent: 0,
  //           },
  //           guideboxes: [
  //             {
  //               guidelineBoxText: "",
  //               leftPercent: 0,
  //               topPercent: 0,
  //               widthPercent: 0,
  //               heightPercent: 0,
  //             },
  //           ],
  //         },
  //       },
  //       embroideryData: {
  //         [`CanvasComponent${partIndex}`]: {
  //           canvasSize: "dynamic",
  //         },
  //         [`DesignAreaComponent${partIndex}`]: {
  //           designAreaText: "",
  //           designableArea: {
  //             leftPercent: 0,
  //             topPercent: 0,
  //             widthPercent: 0,
  //             heightPercent: 0,
  //           },
  //           guideboxes: [
  //             {
  //               guidelineBoxText: "",
  //               leftPercent: 0,
  //               topPercent: 0,
  //               widthPercent: 0,
  //               heightPercent: 0,
  //             },
  //           ],
  //         },
  //       },
  //       colors: productColors,
  //     },
  //   ]);
  // };


  const handleAddPart = () => {
    const partIndex = parts.length + 1;
    const newPart: Part = {
      name: `Part ${partIndex}`,
        screenPrinting: false,
        embroidery: false,
        [`ProductImageComponent${partIndex}`]:
          selectedOption === "Masking"
            ? { image: "" }
            : { colors: [] },
        screenPrintingData: {
          [`CanvasComponent${partIndex}`]: {
            canvasSize: "dynamic",
          },
          [`DesignAreaComponent${partIndex}`]: {
            designAreaText: "",
            designableArea: {
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
            guideboxes: [
              {
                guidelineBoxText: "",
                leftPercent: 0,
                topPercent: 0,
                widthPercent: 0,
                heightPercent: 0,
              },
            ],
          },
        },
        embroideryData: {
          [`CanvasComponent${partIndex}`]: {
            canvasSize: "dynamic",
          },
          [`DesignAreaComponent${partIndex}`]: {
            designAreaText: "",
            designableArea: {
              leftPercent: 0,
              topPercent: 0,
              widthPercent: 0,
              heightPercent: 0,
            },
            guideboxes: [
              {
                guidelineBoxText: "",
                leftPercent: 0,
                topPercent: 0,
                widthPercent: 0,
                heightPercent: 0,
              },
            ],
          },
        },
        colors: productColors,
  };
    setParts([...parts, newPart]);
};

    
  const handleFileUpload = (file: File, partIndex: number, color?: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const partKey = `ProductImageComponent${partIndex + 1}`;
  
      // Ensure the part has the ProductImageComponent initialized
      if (!updatedParts[partIndex][partKey]) {
        updatedParts[partIndex][partKey] = { colors: [], image: "" };
      }
  
      if (color) {
        // Assign the file to the specific color
        updatedParts[partIndex][partKey][color] = file;
  
        // Add the color to the colors array if not already present
        if (!updatedParts[partIndex][partKey].colors.includes(color)) {
          updatedParts[partIndex][partKey].colors.push(color);
        }
      } else {
        // Assign the file directly to `image` for single upload
        updatedParts[partIndex][partKey].image = file;
      }
      console.log("updatedParts",updatedParts);
      return updatedParts; // Return the updated parts array
    });
  
    // Update the files state
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[partIndex] = file;
      return updatedFiles;
    });
  };

  const handleRemovePart = (index: number) => {
    const updatedParts = [...parts];
    updatedParts.splice(index, 1);
    setParts(updatedParts);
  };

  const handleAddGuideBox = (partIndex: number, datavalue: string) => {
    setParts((prevParts) => {
      const updatedParts = [...prevParts];
      const designAreaKey = `DesignAreaComponent${partIndex + 1}`;
  
      // Ensure guideboxes array is initialized
      if (!updatedParts[partIndex][datavalue][designAreaKey].guideboxes) {
        updatedParts[partIndex][datavalue][designAreaKey].guideboxes = [];
      }
  
      updatedParts[partIndex][datavalue][designAreaKey].guideboxes.push({
        guidelineBoxText: "",
        leftPercent: 0,
        topPercent: 0,
        widthPercent: 0,
        heightPercent: 0,
      });
  
      return updatedParts;
    });
  };
  

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // const updatedParts = parts.map((part, index) => {
    //   const partKey = `ProductImageComponent${index + 1}`;
    //   const productImageComponent = part[partKey]?.colors
    //     ? part[partKey].colors.reduce((acc: any, color: string) => {
    //         acc[color] = part[partKey][color];
    //         return acc;
    //       }, {})
    //     : { image: part[partKey]?.image || "" };

    //   return {
    //     name: part.name,
    //     description: JSON.stringify(part),
    //     ...productImageComponent,
    //   };
    // });

    // console.log("------------Submit------------");
    // console.log(data);
    // const jsonData = {
    //   name: data.name,
    //   parts: JSON.stringify(data),
    //   type: selectedOption,
    // };

    const encodeFileToBase64 = (file: File): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof File)) {
          resolve(null);
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };
  
    const updatedParts = await Promise.all(
      data.parts.map(async (part, index) => {
        const partKey = `ProductImageComponent${index + 1}`;
        const productImageComponent: Record<string, string | null> = {};
        const colors: string[] = parts[index][partKey]?.colors || [];
  
        if (parts[index][partKey]) {
          const colorImages = parts[index][partKey];
          for (const color of colors) {
            const file = colorImages[color];
            console.log("------------colors image---------");
            console.log("color",color);
            console.log("file",file);
            if (file instanceof File) {
              productImageComponent[color] = await encodeFileToBase64(file);
            }
            console.log("productImageComponent",productImageComponent[color]);
          }
        }

        let imageOrColors = null;
              if (selectedOption === "Masking") {
                console.log("updatedParts",files);
                // Ensure the image is specific to the part
      const file = parts[index][partKey]?.image;
      imageOrColors = {
        image: file ? await encodeFileToBase64(file) : null, // Encode the single image
      };
              } else if (selectedOption === "Images") {
                imageOrColors = {
                  colors: productImageComponent, // Set colors-related data
                };
              }
        
              console.log("Part:", part.name, "Screen Printing:", part.screenPrinting, "Embroidery:", part.embroidery);

        // Determine canvasType
        let canvasType = "";
        if (screen && emb) {
          canvasType = "screenPrinting, embroidery";
        } else if (screen) {
          canvasType = "screenPrinting";
        } else if (emb) {
          canvasType = "embroidery";
        }
  
      
      
  console.log("updatedParts",imageOrColors);
        // Combine screenPrintingObject and embroideryObject into description
        const description = JSON.stringify({
          screenPrinting: part.screenPrintingData || null,
          embroidery: part.embroideryData || null,
        });
  
        return {
          name: part.name,
          description, // Save the combined object as a string
          canvasType,
          productId: selectedProduct?.id || null, 
          ...imageOrColors,

        };
      })
    );
  
    const jsonData = {
      name: data.name,
      parts: updatedParts,
      type: selectedOption,
    };

    // const apiUrl = isEditMode
    //   ? `https://backend.inkox.com/api/tool-apparel/update/${location.state.detail.name}`
    //   : `https://backend.inkox.com/api/tool-apparel`;
    const apiUrl = isEditMode
      ? `https://backend.inkox.com/api/tool-apparel/update/${location.state.detail.name}`
      : `https://backend.inkox.com/api/tool-apparels`;

    const method = isEditMode ? "PUT" : "POST";

    try {
      const response = await fetch(apiUrl, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response from API:", response.status, errorText);
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      alert(
        isEditMode
          ? "Tool Apparel updated successfully!"
          : "Tool Apparel created successfully!"
      );
      // navigate("/apps/ecommerce/tool-apparel-detail");
    } catch (error) {
      console.error("Error saving tool apparel:", error);
      alert("Error saving Tool Apparel");
    }
  };
console.log("Parts",parts)

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Canvas Id",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Canvas Id"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Apparel
                </h5>
                <FormInput
                  name="name"
                  label="Product Canvas Id"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={(e) => setProductCanvasId(e.target.value)}
                />

                {/* Only show options when Canvas ID is entered */}
                {productCanvasId && (
                  <div className="mb-3">
                    <Form.Check
                      type="radio"
                      label="Single Color Garment"
                      name="option"
                      value="Masking"
                      checked={selectedOption === "Masking"}
                      onChange={handleOptionChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Multi Color Garment"
                      name="option"
                      value="Images"
                      checked={selectedOption === "Images"}
                      onChange={handleOptionChange}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Product with Mask */}
        {selectedOption === "Masking" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleAddPart}
                  >
                    Add Part
                  </button>
                  {parts.map((part, index) => {
          const partIndex = index + 1;
          return (
            <Row key={index}>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                      Part {partIndex}
                    </h5>
                    <FormInput
                      name={`parts[${index}].name`}
                      label="Part Name"
                      placeholder="e.g : Front"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                    />


                    {/* Product Image Upload */}
                    <Form.Group className="mb-3 ">
                      <Form.Label>Product Image {partIndex}</Form.Label>
                      <FileUploader
                        onFileUpload={(files: any) => handleFileUpload(files[0], index)}
                      />
                    </Form.Group>



                   <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}
  // onChange={(e) => {
  //   if(e.target.value == 'on') {
  //     // setScreen(true);
  //     console.log("ScreenPrinting",e.target.value);
  //   }
    
  // }}
  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}
  // onChange={() => {
  //   // setEmb(true);
  //   console.log("Embroidery");
  // }}
  className="mb-3"
/>

{/* Conditionally Render Screen Printing Design & Guideline Areas */}
{part.screenPrinting && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.screenPrintingData?.[`DesignAreaComponent${index + 1}`]?.guideboxes?.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'screenPrintingData')}
                    >
                      Add Guidebox
                    </button>

  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{part.embroidery && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    {/* Guideboxes - Repeatable Section */}
                    {part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo" style={{ display: "none" }}>
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                value={0}
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    {/* <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'embroideryData')}
                    >
                      Add Guidebox
                    </button> */}
                   

  </>
)}




                    {parts.length > 0 && productCanvasId && (
  <div className="text-end mb-3">
    <button
      type="button"
      className="btn w-sm btn-primary waves-effect waves-light me-2"
      onClick={handleAddPart}
    >
      Add Part
    </button>
    <button
      type="button"
      className="btn w-sm btn-danger waves-effect waves-light me-2"
      onClick={() => handleRemovePart(index)}
    >
      Remove Part
    </button>
  </div>
)}

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}


                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {/* Product with Images */}
        {selectedOption === "Images" && productCanvasId && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        handleProductSelect(Number(e.target.value))
                      }
                    >
                      <option value="">Select a Product</option>
                      {products.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  {selectedProduct && (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary mb-3"
                        onClick={handleAddPart}
                      >
                        Add Part
                      </button>
                      {parts.map((part, index) => (

                        
                        <div key={index} className="mb-3">
                          <h6>Part {index + 1}</h6>
                          <FormInput
                            name={`parts[${index}].name`}
                            label="Part Name"
                            placeholder="e.g : Front"
                            register={register}
                            errors={errors}
                            control={control}
                          />

                           <h6>Colors:</h6>
                           <div className="allcolors-canvasid">
                          {part.colors.map((color, colorIndex) => (
                            <div key={colorIndex} className="mb-3">
                              <span>{color}</span>
                              <FileUploader
                               onFileUpload={(files: any) =>
                                 handleFileUpload(files[0], index, color) // Pass file, part index, and color
                               }
                             />
                            </div>
                          ))}
                          </div>




                          <Form.Check
  type="checkbox"
  label="Screen Printing"
  checked={part.screenPrinting}
  name="screenPrinting"
  onChange={() => handleCheckboxChange(index, "screenPrinting")}
  className="mb-2"
/>
<Form.Check
  type="checkbox"
  label="Embroidery"
  name="embroidery"
  checked={part.embroidery}
  onChange={() => handleCheckboxChange(index, "embroidery")}
  className="mb-3"
/>


{part.screenPrinting && (
  <>
    <h6 className="mt-3">Screen Printing</h6>
                          
    <FormInput
                      name={`parts[${index}].screenPrintingData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    <div style={{ display: "none" }}>
                    {part.screenPrintingData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          {/* Guidebox Area - Display in a single row */}
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].screenPrintingData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}

                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'screenPrintingData')}
                    >
                      Add Guidebox
                    </button>
                    </div>

  </>
)}

{/* Conditionally Render Embroidery Design & Guideline Areas */}
{part.embroidery && (
  <>
    <h6 className="mt-3">Embroidery</h6>
                          
    <FormInput
                      name={`parts[${index}].embroideryData.CanvasComponent${index + 1}.canvasSize`}
                      label={`Canvas Size ${index + 1}`}
                      placeholder="e.g : dynamic"
                      value="Dynamic"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                      className="tool-apprealnone"
                    />
 <Row className="tool-apprealborder">
                    <FormInput
                      name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designAreaText`}
                      label={`Design Area ${index + 1}`}
                      placeholder="e.g : Front Design"
                      containerClass={"mb-3"}
                      register={register}
                      errors={errors}
                      control={control}
                       value="Design area"
                       className="tool-apprealnone"

                    />

                    {/* Designable Area - Display in a single row */}
                   
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.leftPercent`}
                          label="Left Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.topPercent`}
                          label="Top Percent"
                          placeholder="e.g : 25"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.widthPercent`}
                          label="Width Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                      <Col md={3}>
                        <FormInput
                          name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.designableArea.heightPercent`}
                          label="Height Percent"
                          placeholder="e.g : 50"
                          type="number"
                           step="0.01"
                          containerClass={"mb-3"}
                          register={register}
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Row>

                    <div style={{ display: "none" }}>
                    {part.embroideryData[`DesignAreaComponent${index + 1}`].guideboxes.map(
                      (box: GuideBox, boxIndex: number) => (
                        <div key={boxIndex} className="row tool-apprealbordertwo">
                          <h6>Guidebox {boxIndex + 1}</h6>
                          <FormInput
                            name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].guidelineBoxText`}
                            label="Guidebox Text"
                            placeholder="e.g : Left Chest"
                            containerClass={"mb-3"}
                            register={register}
                            errors={errors}
                            control={control}
                            className="inputguideline"
                          />

                          
                          <Row>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].leftPercent`}
                                label="Left Percent"
                                value={0}
                                placeholder="e.g : 0"
                                type="number"
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].topPercent`}
                                label="Top Percent"
                                placeholder="e.g : 89"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                register={register}
                                 step="0.01"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].widthPercent`}
                                label="Width Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                containerClass={"mb-3"}
                                 step="0.01"
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                            <Col md={3}>
                              <FormInput
                                name={`parts[${index}].embroideryData.DesignAreaComponent${index + 1}.guideboxes[${boxIndex}].heightPercent`}
                                label="Height Percent"
                                placeholder="e.g : 10"
                                type="number"
                                value={0}
                                 step="0.01"
                                containerClass={"mb-3"}
                                register={register}
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
{/* 
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mb-3"
                      onClick={() => handleAddGuideBox(index,'embroideryData')}
                    >
                      Add Guidebox
                    </button> */}
                    </div>
                   

  </>
)}





                          
                         


                         
                        </div>
                      ))}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn-success waves-effect waves-light me-1"
              >
                Update
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditToolApparel;
