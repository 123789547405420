import React, { useState, useCallback } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// DnD item type
const ItemType = "SIZE";

// Interface for form values
interface FormValues {
  name: string;
}

// Interface for size item props
interface SizeItemProps {
  size: string;
  index: number;
  moveSize: (dragIndex: number, hoverIndex: number) => void;
  handleDeleteSize: (index: number) => void;
}

// Size item component for DnD
const SizeItem: React.FC<SizeItemProps> = ({ size, index, moveSize, handleDeleteSize }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: { index: number }) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveSize(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: "10px",
        backgroundColor: "#f5f5f5",
        display: "inline-block",
        border: "1px solid #000",
        borderRadius: "4px",
        position: "relative",
        cursor: "move",
      }}
    >
      {size}
      <div
        className="custom-cross"
        onClick={() => handleDeleteSize(index)}
        style={{
          position: "absolute",
          top: "-10px",
          right: "-10px",
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      >
        X
      </div>
    </div>
  );
};

const AddSizes: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [size, setSize] = useState<string>(""); // State for input size
  const [sizes, setSizes] = useState<string[]>([]); // Array to store added sizes

  /*
   * form validation schema
   */
  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  /**
   * Handle input change for Product Canvas Id
   */
  const handleProductCanvasIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductCanvasId(e.target.value);
  };

  /**
   * Handle adding a size
   */
  const handleAddSize = () => {
    if (size) {
      if (sizes.includes(size)) {
        window.alert("Size already added."); // Show alert if size already exists
      } else {
        setSizes([...sizes, size]);
        setSize(""); // Reset the size input after adding
      }
    }
  };

  /**
   * Handle deleting a size
   */
  const handleDeleteSize = (index: number) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
  };

  /**
   * Function to move a size in the list
   */
  const moveSize = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedSizes = [...sizes];
      const [movedSize] = updatedSizes.splice(dragIndex, 1);
      updatedSizes.splice(hoverIndex, 0, movedSize);
      setSizes(updatedSizes);
    },
    [sizes]
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const sizesCommaSeparated = sizes.join(", ");
    console.log("Id:", productCanvasId);
    console.log("Sizes:", sizesCommaSeparated);

    const payload = {
      size_name: productCanvasId,
      size: sizesCommaSeparated,
    };

    try {
      const response = await fetch("https://backend.inkox.com/api/sizes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Size Added Successfully");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Add Sizes",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Add Sizes"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Add Sizes</h5>
                <FormInput
                  name="name"
                  label="Id"
                  placeholder="Enter ID"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={handleProductCanvasIdChange}
                />

                {productCanvasId && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Enter Size</Form.Label>
                      <Form.Control
                        type="text"
                        value={size}
                        placeholder="Enter size"
                        onChange={(e) => setSize(e.target.value)}
                      />
                    </Form.Group>
                    <button
                      type="button"
                      className="btn w-sm btn-primary"
                      onClick={handleAddSize}
                    >
                      Add Size
                    </button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {sizes.length > 0 && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Added Sizes</h5>

                  {/* Render sizes in one row with DnD */}
                  <DndProvider backend={HTML5Backend}>
                    <div className="size-box-container mb-3" style={{ display: 'flex', gap: '10px' }}>
                      {sizes.map((size, index) => (
                        <SizeItem
                          key={index}
                          index={index}
                          size={size}
                          moveSize={moveSize}
                          handleDeleteSize={handleDeleteSize}
                        />
                      ))}
                    </div>
                  </DndProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddSizes;
