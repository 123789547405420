import React, { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

interface FormValues {
  name: string;
}

interface ColorEntry {
  name: string;
  hex: string;
}

const InkColors: React.FC = () => {
  const [colorName, setColorName] = useState<string>("");
  const [colorHex, setColorHex] = useState<string>("");
  const [colors, setColors] = useState<ColorEntry[]>([]); // Array to store added colors

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  // Handle adding a new color
  const handleAddColorBox = () => {
    if (colorName && colorHex) {
      if (colors.some((color) => color.hex === colorHex)) {
        alert("Color already added");
      } else {
        setColors([...colors, { name: colorName, hex: colorHex }]);
        setColorName(""); // Reset the color name after adding
        setColorHex(""); // Reset the color hex code after adding
      }
    } else {
      alert("Please enter both the color name and hex code");
    }
  };

  // Handle deleting a color
  const handleDeleteColorBox = (index: number) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  // Handle drag end event
  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedColors = Array.from(colors);
    const [movedItem] = updatedColors.splice(result.source.index, 1);
    updatedColors.splice(result.destination.index, 0, movedItem);

    setColors(updatedColors);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log("Selected Colors:", colors);
  
    // Create comma-separated strings for color names and hex codes
    const colorNames = colors.map((color) => color.name).join(", ");
    const colorHexCodes = colors.map((color) => color.hex).join(", ");
  
    const payload = {
      color_name: colorNames,
      color_hex: colorHexCodes,
    };
  
    try {
      const response = await fetch(
        "https://backend.inkox.com/api/inkcolors",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Added Ink Colors Successfully");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  // Custom styling function to keep the item visible while dragging
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    ...(isDragging && {
      transform: draggableStyle?.transform ? `${draggableStyle.transform}` : "none",
      boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
    }),
    opacity: 1, // Always keep the item visible
  });

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Ink Colors",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Ink Colors"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Ink Colors
                </h5>

                <Form.Group className="mb-3">
                  <Form.Label>Color Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                    placeholder="Enter color name"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Hex Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={colorHex}
                    onChange={(e) => setColorHex(e.target.value)}
                    placeholder="Enter hex code (e.g., #ff5733)"
                  />
                </Form.Group>

                <button
                  type="button"
                  className="btn w-sm btn-primary"
                  onClick={handleAddColorBox}
                >
                  Add Color Box
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {colors.length > 0 && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Added Colors
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="colors" direction="horizontal">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="color-box-container mb-3"
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "10px",
                            border: "1px dashed #ccc",
                            minHeight: "70px",
                          }}
                        >
                          {colors.map((color, index) => (
                            <Draggable key={color.hex} draggableId={color.hex} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100px",
                                      height: "100px",
                                      backgroundColor: color.hex,
                                      display: "inline-block",
                                      border: "1px solid #000",
                                      borderRadius: "5px",
                                      color: "black",
                                      cursor: "grab",
                                    }}
                                  >
                                        {color.name}<br/>
                                        {color.hex}
                                    <div
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "70px",
                                        fontWeight: "bold",
                                        color: "#000",
                                      }}
                                    >
                                  
                                    </div>
                                    {/* Delete icon */}
                                    <div
                                      className="custom-cross"
                                      onClick={() => handleDeleteColorBox(index)}
                                      style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default InkColors;
