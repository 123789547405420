import React, { useState } from "react";
import { Row, Col, Card, ProgressBar } from "react-bootstrap"; // Import ProgressBar component
import { useForm, SubmitHandler } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios"; // You can use axios if you want

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// Interface for form values
interface FormValues {
  file: FileList;
  checkboxValue: boolean; // Add checkbox value to form
}

const Productsync: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Track submission state
  const [progress, setProgress] = useState<number>(0); // Track progress
  const [timeLeft, setTimeLeft] = useState<string>(""); // Track estimated time left
  const [elapsedTime, setElapsedTime] = useState<number>(0); // Track elapsed time

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const file = data.file[0]; // Access the first file
    const formData = new FormData();
    formData.append("file", file); // Append the file
    formData.append("checkboxValue", data.checkboxValue ? '1' : '0'); // Append checkbox value

    setIsSubmitting(true); // Set loading state to true when submitting
    setElapsedTime(0); // Reset elapsed time
    const startTime = Date.now(); // Capture the start time

    const xhr = new XMLHttpRequest();

    xhr.open("POST", "https://backend.inkox.com/api/vendor-json-upload", true);

    // Track progress
    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded / event.total) * 100);
        setProgress(percentCompleted);

        // Calculate elapsed time and time left
        const timeElapsed = (Date.now() - startTime) / 1000; // time in seconds
        setElapsedTime(Math.floor(timeElapsed)); // Set elapsed time
        const uploadSpeed = event.loaded / timeElapsed; // bytes per second
        const timeRemaining = (event.total - event.loaded) / uploadSpeed; // seconds
        setTimeLeft(
          timeRemaining > 60
            ? `${Math.floor(timeRemaining / 60)} min ${Math.floor(timeRemaining % 60)} sec`
            : `${Math.floor(timeRemaining)} sec`
        );
      }
    };

    xhr.onload = function () {
      setIsSubmitting(false);
      if (xhr.status === 200) {
        alert("File Uploaded Successfully");
      } else {
        console.error("Error:", xhr.statusText);
      }
    };

    xhr.onerror = function () {
      setIsSubmitting(false);
      console.error("Error uploading file");
    };

    xhr.send(formData);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Add Sizes",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Vendor JSON file Upload"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Vendor Product Upload</h5>

                {/* File Input */}
                <div className="mb-3">
                  <label htmlFor="file" className="form-label">
                    Upload File
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".json"
                    {...register("file", { required: "File is required" })}
                    className="form-control"
                    disabled={isSubmitting} // Disable input when submitting
                  />
                  {errors.file && (
                    <small className="text-danger">{errors.file.message}</small>
                  )}
                </div>

                {/* Checkbox Input */}
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    id="checkbox"
                    {...register("checkboxValue")}
                    className="form-check-input"
                    disabled={isSubmitting} // Disable checkbox when submitting
                  />
                  <label htmlFor="checkbox" className="form-check-label">
                    Update Image
                  </label>
                </div>

                {/* Progress Bar */}
                {isSubmitting && (
                  <div className="mb-3">
                    <ProgressBar now={progress} label={`${progress}%`} />
                    <small className="text-muted">
                      Estimated Time Left: {timeLeft || "Calculating..."} | Elapsed Time: {elapsedTime}s
                    </small>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
                disabled={isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Productsync;
