import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

interface FontProps {
  revenueHistory: {
    font_path: string;
    name: string;
    created_at: string;
    category: {
      name: string;
    };
    sub_category: {
      name: string;
    };
    reference: string;
    tags: string;
    status: string;
  }[];
}

const FontDetail = () => {

  const [revenueHistory, setRevenueHistory] = useState<FontProps["revenueHistory"]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/fonts");
        const data = await response.json();
        setRevenueHistory(data);
      } catch (error) {
        console.error("Error fetching clipart data:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleEditClick = (item: FontProps["revenueHistory"][0]) => {
    navigate("/apps/ecommerce/edit-fonts", { state: { item } });
  };
  
  return (
    <>
      <Card>
        <Card.Body>
          <Dropdown className="float-end" align="end">
            <Dropdown.Toggle as="a" className="card-drop cursor-pointer">
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Edit Report</Dropdown.Item>
              <Dropdown.Item>Export Report</Dropdown.Item>
              <Dropdown.Item>Action</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <h4 className="header-title mb-3">Font Detail</h4>
          <div className="table-responsive">
            <table className="table table-borderless table-hover table-nowrap table-centered m-0">
              <thead className="table-light">
                <tr>
                  <th>Font Name</th>
                  <th>Date</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {revenueHistory.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {/* Dynamically inject font-face */}
                      <style>
                        {`
                          @font-face {
                            font-family: '${item.name}';
                            src: url('${item.font_path}');
                          }
                        `}
                      </style>
                      {/* Apply the dynamically loaded font */}
                      <h5 className="m-0 fw-normal" style={{ fontFamily: item.name }}>
                        {item.name}
                      </h5>
                    </td>
                    <td>{formatDate(item.created_at)}</td>
                    <td>{item.category?.name || "No Category"}</td>
                    <td>{item.sub_category || "No Sub Category"}</td>
                    <td>
                      <button
                        onClick={() => handleEditClick(item)}
                        className="btn btn-xs btn-light"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default FontDetail;