import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";

interface FileType extends File {
  preview?: string;
  formattedSize?: string;
}

interface AddSubCategoriesProps {
  categoryId: string;
  categoryName: string;
  onSubCategoryAdded: () => void; // Callback to handle when a subcategory is added
  selectedSubCategory?: any; // Prop to pass selected subcategory for editing
}

const AddSubCategories: React.FC<AddSubCategoriesProps> = ({ categoryId, categoryName, onSubCategoryAdded, selectedSubCategory }) => {
  const [subCategoryIcon, setSubCategoryIcon] = useState<FileType[]>([]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      reference: yup.string().required("Subcategory name is required"),
    })
  );

  const methods = useForm({
    resolver: schemaResolver,
    defaultValues: {
      reference: selectedSubCategory?.name || "", // Pre-fill with the selected subcategory name if editing
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = methods;

  // UseEffect to handle editing case
  useEffect(() => {
    if (selectedSubCategory) {
      setValue("reference", selectedSubCategory.name);
      if (selectedSubCategory.image_path) {
        setSubCategoryIcon([{
          name: "existing",
          preview: selectedSubCategory.image_path,  // Assuming 'icon' is the image path
          size: 0,
          type: "",
          lastModified: 0,
          webkitRelativePath: "", // Mock value
          arrayBuffer: async () => new ArrayBuffer(0), // Mock function
          slice: () => new Blob([], { type: "" }), // Mock function
          stream: () => new ReadableStream(), // Mock function
          text: async () => "", // Mock function
        }]);
      }
    } else {
      setValue("reference", ""); // Clear form if adding new
      setSubCategoryIcon([]);
    }
  }, [selectedSubCategory, setValue]);

  const handleSubCategorySave = async (data: any) => {
    const formData = new FormData();
    formData.append('name', data.reference);
    formData.append('category_id', categoryId || ""); // Use the provided category ID
  
    if (subCategoryIcon.length > 0 && subCategoryIcon[0].name !== "existing") {
      formData.append('icon', subCategoryIcon[0]); // Add new icon if uploaded
    }
  
    try {
      const url = selectedSubCategory
        ? `https://backend.inkox.com/api/subcategories/${selectedSubCategory.id}`
        : 'https://backend.inkox.com/api/sub-categories';
      const method = selectedSubCategory ? 'POST' : 'POST'; // POST request in both cases
  
      // Only set headers for updating (PUT override)
      const headers = selectedSubCategory
        ? { 'X-HTTP-Method-Override': 'PUT' }
        : undefined;
  
      const response = await fetch(url, {
        method: method,
        body: formData,
        headers: headers as HeadersInit | undefined, // Fix: Type assertion
      });
      const result = await response.json();
      console.log('Sub Category saved:', result);
      if (response.ok) {
        onSubCategoryAdded(); // Call the callback to close the modal and refresh the list
      }
    } catch (error) {
      console.error('Error saving sub-category:', error);
    }
  };
  
  return (
    <form onSubmit={handleSubmit(handleSubCategorySave)}>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>Assign Main Category</Form.Label>
                <Form.Control
                  type="text"
                  value={categoryName} // Pre-fill with the provided category name
                  disabled // Disable the field so the user cannot change it
                />
              </Form.Group>

              <FormInput
                name="reference"
                label="Sub Category Name"
                placeholder="Enter Sub Category Name"
                containerClass={"mb-3"}
                register={register}
                key="reference"
                errors={errors}
              />
              <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                Sub Category Icon
              </h5>
              <FileUploader
                onFileUpload={(files) => setSubCategoryIcon(files)}
              />

              <Row>
                <Col>
                  <div className="text-center mb-3">
                    <button
                      type="submit"
                      className="btn w-sm btn-success waves-effect waves-light me-1"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default AddSubCategories;
