import React, { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

interface FormValues {
  name: string;
}

const ProductColors: React.FC = () => {
  const [productCanvasId, setProductCanvasId] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<string>("#000000");
  const [colors, setColors] = useState<string[]>([]);

  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const handleProductCanvasIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductCanvasId(e.target.value);
  };

  const handleAddColorBox = () => {
    if (selectedColor) {
      if (colors.includes(selectedColor)) {
        alert("Already Added");
      } else {
        setColors([...colors, selectedColor]);
        setSelectedColor("#000000");
      }
    }
  };

  const handleDeleteColorBox = (index: number) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedColors = Array.from(colors);
    const [movedItem] = updatedColors.splice(result.source.index, 1);
    updatedColors.splice(result.destination.index, 0, movedItem);

    setColors(updatedColors);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const colorsCommaSeparated = colors.join(", ");
    const payload = {
      color_name: productCanvasId,
      color_hex: colorsCommaSeparated,
    };

    try {
      const response = await fetch("https://backend.inkox.com/api/colors", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Color Added Successfully");
      } else {
        console.error("Error:", response.statusText);
        // Handle error
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
    ...(isDragging && {
      transform: draggableStyle?.transform ? `${draggableStyle.transform}` : "none",
      boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
    }),
    opacity: isDragging ? 0.8 : 1, // Keep the item visible while dragging
  });

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Product Colors",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Product Colors"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Product Colors
                </h5>
                <FormInput
                  name="name"
                  label="Colors Id"
                  placeholder="Enter ID"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                  onChange={handleProductCanvasIdChange}
                />

                {productCanvasId && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Color</Form.Label>
                      <Form.Control
                        type="color"
                        value={selectedColor}
                        onChange={(e) => setSelectedColor(e.target.value)}
                      />
                    </Form.Group>
                    <button
                      type="button"
                      className="btn w-sm btn-primary"
                      onClick={handleAddColorBox}
                    >
                      Add Color Box
                    </button>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {colors.length > 0 && (
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Added Colors
                  </h5>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="colors" direction="horizontal">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="color-box-container mb-3"
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "10px",
                            border: "1px dashed #ccc",
                            minHeight: "70px",
                          }}
                        >
                          {colors.map((color, index) => (
                            <Draggable key={color} draggableId={color} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "50px",
                                      height: "50px",
                                      backgroundColor: color,
                                      display: "inline-block",
                                      border: "1px solid #000",
                                      borderRadius: "5px",
                                      cursor: "grab",
                                    }}
                                  >
                                    <div
                                      className="custom-cross"
                                      onClick={() => handleDeleteColorBox(index)}
                                      style={{
                                        position: "absolute",
                                        top: "-10px",
                                        right: "-10px",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ProductColors;
