import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner component
import axios from "axios"; // Import axios

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";

// Interface for form values
interface FormValues {
  stylefile: FileList;
  file: FileList;
  checkboxValue: boolean; // Add checkbox value to form
}

const Productsync: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Track submission state

  /*
   * form validation schema
   */
  const methods = useForm<FormValues>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const stylefile = data.stylefile[0]; // Access the first file
    const file = data.file[0]; // Access the first file
    const formData = new FormData();
    formData.append("file", file); // Append the file
    formData.append("stylefile", stylefile); // Append the file
    if(data.checkboxValue){
        formData.append("checkboxValue", data.checkboxValue ? '1' : '0');
    }
     // Append checkbox value as 1 or 0

    setIsSubmitting(true); // Set loading state to true when submitting

    try {
      const response = await fetch("https://backend.inkox.com/api/vendor-sns-upload", {
        method: "POST",
        body: formData, // Send form data
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);
        alert("Size Added Successfully");
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false); // Set loading state back to false after request completes
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Add Sizes",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Vendor JSON file Upload"}
      />

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">Vendor Product Upload</h5>

                {/* File Input */}
                <div className="mb-3">
                  <label htmlFor="file" className="form-label">
                    Upload File Styles
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".xlsx"
                    {...register("stylefile", { required: "File is required" })}
                    className="form-control"
                    disabled={isSubmitting} // Disable input when submitting
                  />
                  {errors.stylefile && (
                    <small className="text-danger">{errors.stylefile.message}</small>
                  )}
                </div>
                
                <div className="mb-3">
                  <label htmlFor="file" className="form-label">
                    Upload File Products
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".xlsx"
                    {...register("file", { required: "File is required" })}
                    className="form-control"
                    disabled={isSubmitting} // Disable input when submitting
                  />
                  {errors.file && (
                    <small className="text-danger">{errors.file.message}</small>
                  )}
                </div>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
                disabled={isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Submitting...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Productsync;
