import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";

const ProductAdd = () => {
  const [editorState, setEditorState] = useState<any>();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productCanvases, setProductCanvases] = useState([]);
  const [colors, setColors] = useState([]);
  const [productSizes, setProductSizes] = useState([]);

  const fetchCategories = async () => {
    try {


      const response = await fetch('https://backend.inkox.com/api/product-categories');
      const data = await response.json();
      const formattedCategories = data.map((category: any) => ({
        id: category.id,
        value: category.id.toString(),
        label: category.name,
      }));
      setCategories(formattedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchsubCategoryOptions = async () => {
    try {
      const response = await fetch('https://backend.inkox.com/api/product-sub-categories');
      const data = await response.json();
      const formattedCategories = data.map((subcategory: any) => ({
        id: subcategory.id,
        value: subcategory.id.toString(),
        label: subcategory.name,
      }));
      setSubCategories(formattedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProductCanvases = async () => {
    try {
      const response = await fetch('https://backend.inkox.com/api/tool-apparels');
      const data = await response.json();
      const formattedCanvases = data.map((canvas: any) => ({
        id: canvas.id,
        value: canvas.id.toString(),
        label: canvas.name,
      }));
      setProductCanvases(formattedCanvases);
    } catch (error) {
      console.error('Error fetching product canvases:', error);
    }
  };

  const fetchColors = async () => {
    try {
      const response = await fetch('https://backend.inkox.com/api/colors');
      const data = await response.json();
      const formattedColors = data.map((color: any) => ({
        id: color.id,
        value: color.id.toString(),
        label: color.color_name,
      }));
      setColors(formattedColors);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  const fetchProductSizes = async () => {
    try {
      const response = await fetch('https://backend.inkox.com/api/sizes');
      const data = await response.json();
      const formattedSizes = data.map((size: any) => ({
        id: size.id,
        value: size.id.toString(),
        label: size.size_name,
      }));
      setProductSizes(formattedSizes);
    } catch (error) {
      console.error('Error fetching sizes:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchsubCategoryOptions();
    fetchProductCanvases();
    fetchColors();
    fetchProductSizes();
  }, []);

  const schemaResolver = yupResolver(
    yup.object().shape({
    })
  );

  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onEditorStateChange = (editorStates: any) => {
    setEditorState(editorStates);
  };

  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);
  const [selectedCanvasId, setSelectedCanvasId] = useState<any>(null);
  const [selectedColorId, setSelectedColorId] = useState<any>(null);
  const [selectedSizeId, setSelectedSizeId] = useState<any>(null);
  const [fitSizingGuideFiles, setFitSizingGuideFiles] = useState<File[]>([]);
  const [productImagesFiles, setProductImagesFiles] = useState<File[]>([]);


  const onSubmit = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', editorState?.getCurrentContent().getPlainText() || '');
      formData.append('summary', data.summary);
      formData.append('price', data.price);
      formData.append('productColor', selectedColorId?.id);
      formData.append('productSize', selectedSizeId?.id);
      formData.append('brand', data.brand);
      formData.append('material', data.material);
      formData.append('trending_prod', data.trending_prod);
      formData.append('Fabric', data.Fabric);
      formData.append('Weight', data.Weight);
      formData.append('Fit', data.Fit);
      formData.append('metatitle', data.metatitle);
      formData.append('metakeywords', data.metakeywords);
      formData.append('metadescription', data.metadescription);

      formData.append('category_id', selectedCategory?.id);
      formData.append('subCategory_id', selectedSubCategory?.id);
      formData.append('productCanvasId', selectedCanvasId?.id);

      fitSizingGuideFiles.forEach((file) => {
        formData.append('fitAndSizingGuide', file);
      });

      productImagesFiles.forEach((file) => {
        formData.append('productImages[]', file);
      });
      console.log("FormData", formData);

      const response = await fetch('https://backend.inkox.com/api/product', {
        method: 'POST',
        body: formData,
      });

      console.log('Full Response from API:', response);

      alert('Product added successfully');
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product');
    }
  };


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/details" },
          {
            label: "Add Product",
            path: "/apps/ecommerce/details",
            active: true,
          },
        ]}
        title={"Add Product"}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5>
                <FormInput
                  name="name"
                  label="Product Name"
                  placeholder="e.g : Apple iMac"
                  containerClass={"mb-3"}
                  register={register}
                  key="productname"
                  errors={errors}
                  control={control}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Product Description</Form.Label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName border border-1"
                    editorClassName="editorClassName px-2"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{ minHeight: "150px" }}
                  />
                </Form.Group>

                <FormInput
                  type="textarea"
                  rows="3"
                  name="summary"
                  label="Product Summary"
                  placeholder="Please enter summary"
                  containerClass={"mb-3"}
                  register={register}
                  key="summary"
                  errors={errors}
                  control={control}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Categories</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={categories}
                    id="product-category"
                    value={selectedCategory}
                    onChange={(selectedOption) => setSelectedCategory(selectedOption)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sub Categories</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={subCategories}
                    id="product-sub-category"
                    value={selectedSubCategory}
                    onChange={(selectedOption) => setSelectedSubCategory(selectedOption)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Canvas Id</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={productCanvases}
                    id="product-canvas-id"
                    value={selectedCanvasId}
                    onChange={(selectedOption) => setSelectedCanvasId(selectedOption)}
                  />
                </Form.Group>

                <FormInput
                  name="price"
                  label="Price"
                  placeholder="Enter amount"
                  containerClass={"mb-3"}
                  register={register}
                  key="price"
                  errors={errors}
                  control={control}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Product Colors</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={colors}
                    id="productColor"
                    value={selectedColorId}
                    onChange={(selectedOption) => setSelectedColorId(selectedOption)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Product Sizes</Form.Label>
                  <Select
                    className="react-select react-select-container"
                    classNamePrefix="react-select"
                    options={productSizes}
                    id="productSize"
                    value={selectedSizeId}
                    onChange={(selectedOption) => setSelectedSizeId(selectedOption)}
                  />
                </Form.Group>

                <FormInput
                  name="brand"
                  label="Brand"
                  placeholder="Brand Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="brand"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  name="material"
                  label="Material"
                  placeholder="Enter Material"
                  containerClass={"mb-3"}
                  register={register}
                  key="material"
                  errors={errors}
                  control={control}
                />
                 <Form.Group className="mb-3">
                 <Form.Label>Trending Product</Form.Label>
 <FormInput
                  name="trending_prod"
                  type="checkbox"
                  containerClass={"mb-3"}
                  register={register}
                  key="trending_prod"
                  errors={errors}
                  control={control}
                />
                 </Form.Group>

                <FormInput
                  name="Fabric"
                  label="Fabric"
                  placeholder="Enter Fabric"
                  containerClass={"mb-3"}
                  register={register}
                  key="Fabric"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  name="Weight"
                  label="Weight"
                  placeholder="Enter Weight"
                  containerClass={"mb-3"}
                  register={register}
                  key="Weight"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  name="Fit"
                  label="Fit"
                  placeholder="Enter Fit"
                  containerClass={"mb-3"}
                  register={register}
                  key="Fit"
                  errors={errors}
                  control={control}
                />

                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Fit And Sizing Guide
                </h5>
                <FileUploader onFileUpload={(files) => setFitSizingGuideFiles(files)} />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Product Images
                </h5>
                <FileUploader onFileUpload={(files) => setProductImagesFiles(files)} />
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  Meta Data
                </h5>
                <FormInput
                  name="metatitle"
                  label="Meta title"
                  placeholder="Enter title"
                  containerClass={"mb-3"}
                  register={register}
                  key="metatitle"
                  errors={errors}
                  control={control}
                />
                <FormInput
                  name="metakeywords"
                  label="Meta Keywords"
                  placeholder="Enter keywords"
                  containerClass={"mb-3"}
                  register={register}
                  key="metakeywords"
                  errors={errors}
                  control={control}
                />
                <FormInput
                  type="textarea"
                  rows="5"
                  name="metadescription"
                  label="Meta Description"
                  placeholder="Please enter description"
                  containerClass={"mb-3"}
                  register={register}
                  key="metadescription"
                  errors={errors}
                  control={control}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn w-sm btn-light waves-effect me-1"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ProductAdd;