import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import classNames from "classnames";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// Order column render function
const OrderColumn = ({ row }: { row: any }) => {
  return (
    <Link to="/apps/ecommerce/order/details" className="text-body fw-bold">
      {row.original.id}
    </Link>
  );
};

// OrderDate column render function
const OrderDateColumn = ({ row }: { row: any }) => {
  return <>{new Date(row.original.created_at).toLocaleDateString()}</>;
};



// Column to display ink colors as a palette
const InkColorPaletteColumn = ({ row }: { row: any }) => {
  const inkColors = row.original.inkColors || [];

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {inkColors.map((color: string, index: number) => (
        <div key={index} style={{ width: "20px", height: "20px", backgroundColor: color, border: "1px solid #000" }}></div>
      ))}
    </div>
  );
};

// Total column render function
const TotalColumn = ({ row }: { row: any }) => {
  return <>${row.original.totalPrice}</>;
};

// PartsImage column render function
const PartsImageColumn = ({ row }: { row: any }) => {
    const imagePreviews = row.original.imagePreviews || [];
  
    return (
      <>
        {imagePreviews.length > 0 ? (
          imagePreviews.map((image: { preview: string; part: string }, index: number) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              {/* <p>{image.part}</p> */}
              <img
                src={`${image.preview}`}
                alt={`Preview of ${image.part}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          ))
        ) : (
          <p>No images available</p>
        )}
      </>
    );
  };
  

// Define the type for uploaded image data
type UploadedImage = {
  imageSrc: string;
  part: string;
  width: number;
  height: number;
  left?: number;
  top?: number;
  scaleX?: number;
  scaleY?: number;
};

const UploadedImageColumn = ({ row }: { row: any }) => {
  const uploadedImageData: UploadedImage[] = row.original.uploadedImageData || [];

  // Filter out blob URLs and only keep base64 images
  const base64Images = uploadedImageData.filter(
    (image) => image.imageSrc.startsWith('data:image/')
  );

  // Remove duplicate images based on imageSrc and part
  const uniqueImages = base64Images.filter((image, index, self) => 
    index === self.findIndex((img) => img.imageSrc === image.imageSrc && img.part === image.part)
  );

  return (
    <>
      {uniqueImages.length > 0 ? (
        uniqueImages.map((uploaded: UploadedImage, index: number) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            {/* <p>{uploaded.part} Image</p> */}
            <img
              src={uploaded.imageSrc}
              alt={`Uploaded ${uploaded.part} Image ${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onError={(e) => { e.currentTarget.src = "/path/to/placeholder-image.jpg"; }} // Handle broken image
            />
          </div>
        ))
      ) : (
        <p>No uploaded images available</p>
      )}
    </>
  );
};

  
  

// ClipArtImage column render function
const ClipArtImageColumn = ({ row }: { row: any }) => {
  const clipArtData = row.original.clipArtData || [];
  
  return (
    <>
      {clipArtData.length > 0 ? (
        clipArtData.map((clip: any, index: number) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            {/* <p>{clip.part} Clip Art</p> */}
            <img
              src={clip.clipartPath}  // Fixed: Using clipartPath instead of imageSrc
              alt={`Clip Art ${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        ))
      ) : (
        <p>No Clip Art</p>
      )}
    </>
  );
};

// PaymentStatus column render function
const PaymentStatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success": row.original.paymentStatus === "Paid",
          "bg-soft-danger text-danger": row.original.paymentStatus === "Payment Failed",
          "bg-soft-info text-info": row.original.paymentStatus === "Unpaid",
          "bg-soft-warning text-warning": row.original.paymentStatus === "Awaiting Authorization",
        })}
      >
        {row.original.paymentStatus === "Paid" && <i className="mdi mdi-bitcoin me-1"></i>}
        {row.original.paymentStatus === "Payment Failed" && <i className="mdi mdi-cancel me-1"></i>}
        {row.original.paymentStatus === "Unpaid" && <i className="mdi mdi-cash me-1"></i>}
        {row.original.paymentStatus === "Awaiting Authorization" && <i className="mdi mdi-timer-sand me-1"></i>}
        {row.original.paymentStatus}
      </span>
    </h5>
  );
};

// PaymentMethod column render function
const PaymentMethodColumn = ({ row }: { row: any }) => {
  return <>{row.original.paymentMethod}</>;
};

// Status column render function
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <h5>
      <span
        className={classNames("badge", {
          "bg-success": row.original.orderStatus === "Delivered",
          "bg-danger": row.original.orderStatus === "Cancelled",
          "bg-info": row.original.orderStatus === "Shipped",
          "bg-warning": row.original.orderStatus === "Processing",
        })}
      >
        {row.original.orderStatus}
      </span>
    </h5>
  );
};

// Action column render function
const ActionColumn = ({ row, onEditClick, onDeleteClick }: { row: any; onEditClick: (id: number) => void; onDeleteClick: (id: number) => void }) => {
  return (
    <>
      <Link to="#" className="action-icon" onClick={() => onEditClick(row.original.id)}>
        <i className="mdi mdi-eye"></i>
      </Link>
      <Link to="#" className="action-icon" onClick={() => onEditClick(row.original.id)}>
        <i className="mdi mdi-square-edit-outline"></i>
      </Link>
      <Link to="#" className="action-icon" onClick={() => onDeleteClick(row.original.id)}>
        <i className="mdi mdi-delete"></i>
      </Link>
    </>
  );
};

// Function to get columns with edit handler
const getColumns = (handleEditClick: (id: number) => void, handleDeleteClick: (id: number) => void) => [
  {
    Header: "Order ID",
    accessor: "id",
    Cell: OrderColumn,
  },
  {
    Header: "Product Name",
    accessor: "productName",
    Cell: ({ row }: { row: any }) => <div>{row.original.productName}</div>,
  },
  {
    Header: "Email",
    accessor: "Email",
    Cell: ({ row }: { row: any }) => <div>{row.original.Email}</div>,
  },
  {
    Header: "Design Name",
    accessor: "designName",
    Cell: ({ row }: { row: any }) => <div>{row.original.designName}</div>,
  },
  {
    Header: "Zip Code",
    accessor: "zipCode",
    Cell: ({ row }: { row: any }) => <div>{row.original.zipCode}</div>,
  },
  {
    Header: "Total Quantity",
    accessor: "totalQuantity",
    Cell: ({ row }: { row: any }) => <div>{row.original.totalQuantity}</div>,
  },
  {
    Header: "Unit Price",
    accessor: "unitPrice",
    Cell: ({ row }: { row: any }) => <div>${row.original.unitPrice}</div>,
  },
  {
    Header: "Base Price",
    accessor: "basePrice",
    Cell: ({ row }: { row: any }) => <div>${row.original.basePrice}</div>,
  },
  {
    Header: "Ink Colors",
    accessor: "inkColors",
    Cell: InkColorPaletteColumn,  // Display the ink colors as a palette
  },
  {
    Header: "Parts Image",
    accessor: "partsImage",
    Cell: PartsImageColumn,
  },
  {
    Header: "Uploaded Image",
    accessor: "uploadedImage",
    Cell: UploadedImageColumn,
  },
  {
    Header: "Clip Art Image",
    accessor: "clipArtImage",
    Cell: ClipArtImageColumn,
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: OrderDateColumn,
  },
  {
    Header: "Payment Status",
    accessor: "payment_status",
    Cell: PaymentStatusColumn,
  },
  {
    Header: "Total",
    accessor: "totalPrice",
    Cell: TotalColumn,
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
    Cell: PaymentMethodColumn,
  },
  {
    Header: "Order Status",
    accessor: "order_status",
    Cell: StatusColumn,
  },
];


// Pagination size options
const sizePerPageList = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "50",
    value: 50,
  },
];

const CartDetail = () => {
  const [orderList, setOrderList] = useState([]);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null); // Selected row id
  const [paymentStatus, setPaymentStatus] = useState<string>(""); // Selected payment status
  const [orderStatus, setOrderStatus] = useState<string>(""); // Selected order status
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState<number | null>(null);

  // Fetch the orders from API using fetch
  useEffect(() => {
  const fetchOrders = async () => {
    try {
      const response = await fetch("https://laravel-incox.hebesol.com/api/checkouts");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrderList(data);  // Update state with fetched data
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  fetchOrders();
}, []);

  // Handle edit button click
  const handleEditClick = (orderId: number) => {
    const selectedOrder: any = orderList.find((order: any) => order.id === orderId);
    if (selectedOrder) {
      setSelectedOrderId(orderId);
      setPaymentStatus(selectedOrder.paymentStatus);
      setOrderStatus(selectedOrder.orderStatus);
      setShowModal(true);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrderId(null);
  };

  // Handle form submission to update payment and order status
  const handleUpdateStatus = async () => {
    if (selectedOrderId) {
      try {
        const payload = {
          paymentStatus: paymentStatus,
          orderStatus: orderStatus,
        };

        const response = await fetch(`https://laravel-incox.hebesol.com/api/checkouts/${selectedOrderId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          // Successfully updated
          alert("Order updated successfully!");
          setShowModal(false);
        } else {
          alert("Failed to update the order.");
        }
      } catch (error) {
        console.error("Error updating order:", error);
        alert("An error occurred while updating the order.");
      }
    }
  };

  const handleDeleteClick = (orderId: number) => {
    setDeleteOrderId(orderId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteOrderId(null);
  };

  const handleDelete = async () => {
    if (deleteOrderId) {
      try {
        const response = await fetch(`https://laravel-incox.hebesol.com/api/checkouts/${deleteOrderId}`, {
          method: "DELETE",
        });

        if (response.ok) {
          alert("Order deleted successfully!");
          setOrderList(orderList.filter((order: any) => order.id !== deleteOrderId));
          handleCloseDeleteModal();
        } else {
          alert("Failed to delete the order.");
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        alert("An error occurred while deleting the order.");
      }
    }
  };
  const columns = useMemo(() => getColumns(handleEditClick, handleDeleteClick), [handleEditClick, handleDeleteClick]);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/apps/ecommerce/orders" },
          { label: "Orders", path: "/apps/ecommerce/orders", active: true },
        ]}
        title={"Orders"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={8}>
                  <form className="row gy-2 gx-2 align-items-center justify-content-lg-start justify-content-between">
                    <div className="col-auto">
                      <div className="d-flex align-items-center w-auto">
                        <label htmlFor="status-select" className="me-2">
                          Status
                        </label>
                        <select className="form-select" id="status-select">
                          <option value="All">All</option>
                          <option value="Paid">Paid</option>
                          <option value="Authorization">Awaiting Authorization</option>
                          <option value="Failed">Payment failed</option>
                          <option value="Unpaid">Unpaid</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>

              <Table
                columns={columns} // Pass the edit button handler to the table
                data={orderList}
                pageSize={10}
                sizePerPageList={sizePerPageList} // Pagination size options
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for updating status */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Payment Status</Form.Label>
              <Form.Select value={paymentStatus}>
                <option value="">Select Payment Status</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="Awaiting Authorization">Awaiting Authorization</option>
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Order Status</Form.Label>
              <Form.Select value={orderStatus}>
                <option value="">Select Order Status</option>
                <option value="Delivered">Delivered</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Shipped">Shipped</option>
                <option value="Processing">Processing</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateStatus}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartDetail;
