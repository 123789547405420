// import React, { useState, useEffect } from "react";
// import { Row, Col, Card, Form } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useLocation, useNavigate } from "react-router-dom";
// import FileUploader from "../../../components/FileUploader";
// import { FormInput } from "../../../components/";
// import Select from "react-select";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// interface FontFormData {
//   name: string;
//   category: string;
//   sub_category: string;
//   font_file?: string | null;
// }

// interface CategoryOption {
//   label: string;
//   value: string;
// }

// const EditFont = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const fontData = location.state?.item;
//   console.log("Font", fontData)
//   const [fontFile, setFontFile] = useState<string | null>(null);
//   const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
//   const [selectedCategory, setSelectedCategory] = useState<CategoryOption | null>(null);

//   // Validation schema using Yup
//   const schema = yup.object().shape({
//     name: yup.string().required("Font name is required"),
//     sub_category: yup.string().required("Sub category is required"),
//   });

//   // Use react-hook-form for form handling
//   const { register, handleSubmit, setValue, formState: { errors } } = useForm<FontFormData>({
//     resolver: yupResolver(schema),
//   });

//   // Fetch categories from the API
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/fontCategories");
//         const data = await response.json();
//         const options: CategoryOption[] = data.map((category: { id: number; name: string }) => ({
//           label: category.name,
//           value: category.id.toString(),
//         }));
//         setCategoryOptions(options);

//         // If editing, set the selected category
//         if (fontData) {
//           const selected = options.find((option: CategoryOption) => option.label === fontData.category.name);
//           setSelectedCategory(selected || null);
//         }
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };
//     fetchCategories();
//   }, [fontData]);

//   // Pre-fill the form with data from FontDetails component
//   useEffect(() => {
//     if (fontData) {
//       setValue("name", fontData.name);
//       setValue("sub_category", fontData.sub_category);
//     }
//   }, [fontData, setValue]);

//   // Handle font file upload and convert to base64
//   const handleFileUpload = (files: File[]) => {
//     if (files && files.length > 0) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64String = reader.result?.toString();
//         setFontFile(base64String || null);
//       };
//       reader.readAsDataURL(files[0]);
//     }
//   };

//   // Handle category selection
//   const handleCategoryChange = (selectedOption: CategoryOption | null) => {
//     setSelectedCategory(selectedOption);
//     setValue("category", selectedOption ? selectedOption.label : "");
//   };

//   // Submit the form data to the API
//   const onSubmit = async (data: FontFormData) => {
//     try {
//       const updatedData = {
//         ...data,
//         category: selectedCategory ? selectedCategory.value : data.category,
//         font_file: fontFile,
//       };

//       const response = await fetch(`http://127.0.0.1:8000/api/fonts/${fontData.id}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(updatedData),
//       });

//       if (response.ok) {
//         toast.success("Font updated successfully!", {
//           position: "bottom-right",
//           onClose: () => navigate("/apps/ecommerce/font-detail"),
//         });
//       }
//     } catch (error) {
//       toast.error("Error updating Font!", {
//         position: "bottom-right",
//       });
//       console.error("Error updating font:", error);
//     }
//   };

//   return (
//     <>
//       <ToastContainer />

//       <form onSubmit={handleSubmit(onSubmit)}>
//         <Row>
//           <Col lg={6}>
//             <Card>
//               <Card.Body>
//                 <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
//                   General
//                 </h5>

//                 <FormInput
//                   name="name"
//                   label="Font Name"
//                   placeholder="Enter Font Name"
//                   containerClass={"mb-3"}
//                   register={register}
//                   errors={errors}
//                 />

//                 <div className="mb-3">
//                   <label htmlFor="category" className="form-label">
//                     Category
//                   </label>
//                   <Select
//                     value={selectedCategory}
//                     onChange={handleCategoryChange}
//                     options={categoryOptions}
//                     placeholder="Select Category"
//                     isClearable
//                   />
//                 </div>

//                 <FormInput
//                   name="sub_category"
//                   label="Sub Category"
//                   placeholder="Enter Sub Category"
//                   containerClass={"mb-3"}
//                   register={register}
//                   errors={errors}
//                 />
//               </Card.Body>
//             </Card>
//           </Col>

//           <Col lg={6}>
//             <Card>
//               <Card.Body>
//                 <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
//                   Font Uploader
//                 </h5>
//                 <FileUploader onFileUpload={handleFileUpload} />
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         <Row>
//           <Col>
//             <div className="text-center mb-3">
//               <button
//                 type="button"
//                 className="btn w-sm btn-light waves-effect me-1"
//               onClick={() => navigate("/apps/ecommerce/font-detail")}
//               >
//                 Cancel
//               </button>
//               <button
//                 type="submit"
//                 className="btn w-sm btn-success waves-effect waves-light me-1"
//               >
//                 Save
//               </button>
//             </div>
//           </Col>
//         </Row>
//       </form>
//     </>
//   );
// };

// export default EditFont;


import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components/";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FontFormData {
  name: string;
  category: string;
  sub_category: string;
  font_file?: File | null;  // Adjusted type for font_file
}

interface CategoryOption {
  label: string;
  value: string;
}

const EditFont = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fontData = location.state?.item;
  console.log("Font", fontData);
  const [fontFile, setFontFile] = useState<File | null>(null);  // File type for font file
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryOption | null>(null);

  // Validation schema using Yup
  const schema = yup.object().shape({
    name: yup.string().required("Font name is required"),
    sub_category: yup.string().required("Sub category is required"),
  });

  // Use react-hook-form for form handling
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FontFormData>({
    resolver: yupResolver(schema),
  });

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://backend.inkox.com/api/fontCategories");
        const data = await response.json();
        const options: CategoryOption[] = data.map((category: { id: number; name: string }) => ({
          label: category.name,
          value: category.id.toString(),
        }));
        setCategoryOptions(options);

        // If editing, set the selected category
        if (fontData) {
          const selected = options.find((option: CategoryOption) => option.label === fontData.category.name);
          setSelectedCategory(selected || null);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [fontData]);

  // Pre-fill the form with data from FontDetails component
  useEffect(() => {
    if (fontData) {
      setValue("name", fontData.name);
      setValue("sub_category", fontData.sub_category);
    }
  }, [fontData, setValue]);

  // Handle font file upload
  const handleFileUpload = (files: File[]) => {
    if (files && files.length > 0) {
      setFontFile(files[0]);  // Set the uploaded file directly
    }
  };

  // Handle category selection
  const handleCategoryChange = (selectedOption: CategoryOption | null) => {
    setSelectedCategory(selectedOption);
    setValue("category", selectedOption ? selectedOption.label : "");
  };

  // Submit the form data to the API
  const onSubmit = async (data: FontFormData) => {
    try {
      
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("sub_category", data.sub_category);
      formData.append("category", selectedCategory ? selectedCategory.value : data.category);

      
      if (fontFile) {
        formData.append("font_file", fontFile);  // Append file to FormData
      }
      
      if (fontFile) {
        const fileExtension = fontFile.name.split(".").pop()?.toLowerCase();
        if (fileExtension !== "ttf") {
          toast.error("Error: Font file must be in .ttf format!", {
            position: "bottom-right",
          });
          return;
        }
      }
      
      const response = await fetch(`https://backend.inkox.com/api/fonts/${fontData.id}`, {
        method: "PUT",
        body: formData,  // Send FormData
      });

      if (response.ok) {
        toast.success("Font updated successfully!", {
          position: "bottom-right",
          // onClose: () => navigate("/apps/ecommerce/font-detail"),
        });
      }
    } catch (error) {
      toast.error("Error updating !", {
        position: "bottom-right",
      });
      console.error("Error updating font:", error);
    }
  };

  return (
    <>
      <ToastContainer />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5>

                <FormInput
                  name="name"
                  label="Font Name"
                  placeholder="Enter Font Name"
                  containerClass={"mb-3"}
                  register={register}
                  errors={errors}
                />

                <div className="mb-3">
                  <label htmlFor="category" className="form-label">
                    Category
                  </label>
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    placeholder="Select Category"
                    isClearable
                  />
                </div>

                <FormInput
                  name="sub_category"
                  label="Sub Category"
                  placeholder="Enter Sub Category"
                  containerClass={"mb-3"}
                  register={register}
                  errors={errors}
                />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  Font Uploader
                </h5>
                <FileUploader onFileUpload={handleFileUpload} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <button
                type="button"
                className="btn w-sm btn-light waves-effect me-1"
                onClick={() => navigate("/apps/ecommerce/font-detail")}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-success waves-effect waves-light me-1"
              >
                Save
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default EditFont;
