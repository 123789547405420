import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import AddClipart from './AddClipart'; // Import AddClipart form component
import "./style.css";

// Define the Clipart type
interface Clipart {
  id: number;
  name: string;
  image_path: string;
  sub_category: string;
  icon: string;
  status: string;
  created_at: string;
  updated_at: string;
}

const ClipartCreate = () => {
  const { subCategoryId } = useParams<{ subCategoryId: string }>(); // Get subCategory ID from the URL
  const [cliparts, setCliparts] = useState<Clipart[]>([]);
  const [subCategoryName, setSubCategoryName] = useState<string>(""); // State to store the subcategory name
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility
  const [selectedClipart, setSelectedClipart] = useState<Clipart | null>(null); // State for the clipart to edit
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control delete confirmation modal
  const [itemToDelete, setItemToDelete] = useState<Clipart | null>(null); // State for the clipart to delete

  // Fetch cliparts based on the selected subcategory
  const fetchCliparts = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/cliparts`);
      const data = await response.json();
  
      const filteredCliparts = data.filter(
        (clipart: Clipart) => parseInt(clipart.sub_category, 10) === parseInt(subCategoryId || '0', 10)
      );
  
      setCliparts(filteredCliparts); // Set filtered cliparts
    } catch (error) {
      console.error("Error fetching cliparts:", error);
    }
  };

  // Fetch the subcategory name based on subCategoryId
  const fetchSubCategoryName = async () => {
    try {
      const response = await fetch(`https://backend.inkox.com/api/sub-categories/${subCategoryId}`);
      const subCategory = await response.json();
      setSubCategoryName(subCategory.name); // Set the subcategory name
    } catch (error) {
      console.error("Error fetching subcategory name:", error);
    }
  };

  const handleClipartAddedOrUpdated = (clipart: any) => {
    // Re-fetch cliparts after adding or updating
    setShowModal(false);
    fetchCliparts(); // Refresh the list to reflect changes
    setSelectedClipart(null);
  };

  // Handle delete button click
  const handleDeleteClick = (clipart: Clipart) => {
    setItemToDelete(clipart);
    setShowDeleteModal(true);
  };

  // Handle confirm delete
  const confirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      const response = await fetch(`https://backend.inkox.com/api/cliparts/${itemToDelete.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete clipart');
      }

      setShowDeleteModal(false);
      setCliparts((prevCliparts) => prevCliparts.filter((c) => c.id !== itemToDelete.id));
      setItemToDelete(null);
    } catch (error) {
      console.error('Error deleting clipart:', error);
    }
  };

  const handleEditClick = (clipart: Clipart) => {
    setSelectedClipart(clipart);
    setShowModal(true);
  };

  useEffect(() => {
    fetchCliparts(); // Fetch cliparts when subCategoryId changes
    fetchSubCategoryName(); // Fetch subcategory name when subCategoryId changes
  }, [subCategoryId]);

  return (
    <>
      <Card>
        <Card.Body>
          <PageTitle
            breadCrumbItems={[
              { label: "Cliparts", path: "/apps/ecommerce/cliparts", active: true },
              { label: `${subCategoryName}`, path: `/apps/ecommerce/cliparts/${subCategoryId}` },
            ]}
            title={`${subCategoryName}`}
          />
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-lg-end mt-xl-0 mt-2">
                <Button
                  className="btn  mb-2 me-2"
                  style={{ backgroundColor: "#1cbed9", borderColor: "#1cbed9" }}
                  onClick={() => {
                    setSelectedClipart(null); // Clear selected clipart when adding new
                    setShowModal(true);
                  }}
                >
                  <i className="mdi mdi-basket me-1"></i> Add New Clipart
                </Button>
              </div>
            </Col>
          </Row>
          <h4 className="header-title mb-3">Clipart Details</h4>
          <div className="table-responsivess">
            <div className="abdu-cls">
              {cliparts.length > 0 ? (
                cliparts.map((clipart, i) => (
                  <div key={i} className="inner-clipart">
                    <img
                      src={`${clipart.image_path}`}
                      className="image-clipartcategory"
                      alt={clipart.name}
                    />
                    <div>{clipart.name}</div>

                    <button
                      className="btn btn-xs btn-light"
                      onClick={() => handleEditClick(clipart)}
                    >
                      <i className="mdi mdi-pencil"></i> Edit
                    </button>

                    <button
                      className="btn btn-xs btn-light"
                      onClick={() => handleDeleteClick(clipart)}
                    >
                      <i className="mdi mdi-trash-can-outline"></i> Delete
                    </button>

                    <button
                      style={{ height: "18%" }}
                      className={`badge ${clipart.status === "active" ? "bg-soft-success text-success" : "bg-soft-danger text-danger"}`}
                    >
                      {clipart.status === "active" ? "Active" : "Deactive"}
                    </button>
                  </div>
                ))
              ) : (
                <p>No cliparts found for this subcategory.</p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Modal for adding/editing clipart */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedClipart ? 'Edit Clipart' : 'Add New Clipart'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClipart
            subCategoryName={subCategoryName || ''}
            subCategoryId={subCategoryId || ''}
            onClipartAdded={handleClipartAddedOrUpdated}
            selectedClipart={selectedClipart ?? undefined} // Pass selected clipart for editing
          />
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal for Delete */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this clipart?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClipartCreate;
